import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-reload-app',
  templateUrl: './reload-app.component.html',
  styleUrls: ['./reload-app.component.scss']
})
export class ReloadAppComponent implements OnInit {
  @Input() currentUI: string;
  @Input() minimumUI: string;
  showHints = false;

  constructor() {}

  ngOnInit(): void {}

  reloadUi() {
    window.open(window.location.href, '', null, true);
    window.close();
  }
}
