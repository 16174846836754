import * as firebase from 'firebase/app';

import { Injectable, Injector } from '@angular/core';

import { AngularFirestore } from '@angular/fire/firestore';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SkillsCheckModalComponent } from '../skills-check-modal/skills-check-modal.component';
import { newAgentOrGlobalAgentTeamChange } from '../utils/globalAgent';
import { AuthService } from './auth.service';
import { SignoutService } from './signout.service';
import { TestJobsService } from './test-jobs.service';

@Injectable({
  providedIn: 'root'
})
export class SkillsCheckService {
  currentSkillsCheckModal: NgbModalRef = null;
  private signoutService: SignoutService;
  skillsChecked = new BehaviorSubject<boolean>(true);
  private skillsCheckTimer: NodeJS.Timeout = null;
  private mainSub: Subscription;

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService,
    private modalService: NgbModal,
    private testJobsService: TestJobsService,
    private injector: Injector
  ) {
    this.startSkillsCheckMonitorForGlobalAgents();
    this.openSkillsCheck = this.openSkillsCheck.bind(this);
  }

  openSkillsCheck() {
    this.stopSkillsCheckTimer();

    this.skillsChecked.next(false);

    this.currentSkillsCheckModal = this.modalService.open(SkillsCheckModalComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false
    });

    this.currentSkillsCheckModal.result
      .then(() => {
        this.skillsChecked.next(true);
        this.logSkillsCheckResult(true);
        this.startSkillsCheckTimer();
      })
      .catch(() => {
        // NOTE: This signout service has to be injected after construction due to a circular dependancy.
        // Not ideal, but better than adding some weird signout signal here and in the signout service.
        this.logSkillsCheckResult(false);
        if (!this.signoutService) {
          this.signoutService = this.injector.get(SignoutService);
        }

        this.signoutService.signout();
      })
      .finally(() => {
        this.currentSkillsCheckModal = null;
      });
  }

  async logSkillsCheckResult(result: boolean) {
    const event = {
      result,
      id: this.afs.createId(),
      agent: this.authService.getAgent(),
      timestamp: firebase.firestore.FieldValue.serverTimestamp()
    };

    await this.afs
      .collection(`agentSkillsChecks`)
      .doc(event.id)
      .set(event)
      .catch(err => {
        console.error('Error logging skills check:', err);
      });
  }

  stopSkillsCheckMonitor() {
    console.log('Stop skills check monitor');

    this.currentSkillsCheckModal?.close();

    this.stopSkillsCheckTimer();
    if (this.mainSub) {
      this.mainSub.unsubscribe();
      this.mainSub = null;
    }
  }

  startSkillsCheckMonitorForGlobalAgents() {
    if (this.mainSub || this.testJobsService.isTestJob) {
      return;
    }

    console.log('Start skills check monitor');
    this.mainSub = this.authService.agent$
      .pipe(
        newAgentOrGlobalAgentTeamChange(),
        map(agent => {
          if (agent?.aid === 'GLOBAL' && !agent.bypassSkillsCheck) {
            return true;
          }
          return false;
        })
      )
      .subscribe(runSkillsCheck => {
        if (runSkillsCheck) {
          this.startSkillsCheckTimer(false);
        } else {
          this.stopSkillsCheckTimer();
        }
      });
  }

  private startSkillsCheckTimer(checkMainSub = true) {
    if (this.skillsCheckTimer || (checkMainSub && !this.mainSub)) {
      return;
    }

    this.skillsCheckTimer = setTimeout(() => this.openSkillsCheck(), 1000 * 60 * (75 + Math.random() * 20));
  }

  private stopSkillsCheckTimer() {
    if (this.skillsCheckTimer) {
      clearTimeout(this.skillsCheckTimer);
    }
    this.skillsCheckTimer = null;
  }
}
