import * as firebase from 'firebase/app';

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Conversation, QuestionBase, SelectQuestion } from '../models/conversation';
import {
  CompletedEvent,
  EventCssClass,
  EventState,
  MMSEvent,
  OptOutEvent,
  TextEvent,
  TransferEvent
} from '../models/event';

import { Subscription } from 'rxjs';
import { ConvActionsFooterDefaultComponent } from '../conv-actions-footer-default/conv-actions-footer-default.component';
import { AiSuggestion } from '../models/ai';
import { Template } from '../models/job';
import { AuthService } from '../services/auth.service';
import { ConvsService } from '../services/convs.service';
import { JobsService } from '../services/jobs.service';
import { TemplateService } from '../services/template.service';

@Component({
  selector: 'app-conv-actions-footer',
  templateUrl: './conv-actions-footer.component.html',
  styleUrls: ['./conv-actions-footer.component.scss']
})
export class ConvActionsFooterComponent implements OnChanges, OnInit {
  @ViewChild('actionsFooterDefault', { static: false })
  actionsDefaultFooter: ConvActionsFooterDefaultComponent;

  @Output() event = new EventEmitter<CompletedEvent | TextEvent | MMSEvent | TransferEvent | OptOutEvent>();

  @Input() aiSuggestion: AiSuggestion;
  @Input() conversation: Conversation;
  @Input() sendButtonStatus: string;

  currentlyDisplayedComponent = 'default';
  subs: Subscription[] = [];
  templateText: string;
  templateMediaUrl: string;
  templateMediaThumbnailUrl: string;

  constructor(
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    private convsService: ConvsService,
    private jobsService: JobsService,
    private templateService: TemplateService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.conversation) {
      if (
        (this.conversation.state === EventCssClass.new || this.conversation.state === EventCssClass.rehash) &&
        this.isDifferentConversation(changes)
      ) {
        this.insertDefaultTemplate();
      } else {
        this.templateText = null;
        if (
          this.actionsDefaultFooter &&
          changes.conversation.previousValue.id !== changes.conversation.currentValue.id
        ) {
          this.actionsDefaultFooter.injectTemplate(null);
        }
      }
    }
  }

  ngOnInit() {}

  insertDefaultTemplate() {
    if (this.jobsService.getCurrentJob()) {
      const template = this.jobsService.getCurrentJob().templates.find(t => t.is_default);
      this.onSelectedTemplate(template);
    }
  }

  onGoTo(comp: string) {
    this.currentlyDisplayedComponent = comp;
  }

  onSendMessage(event: TextEvent | MMSEvent) {
    event.agent = this.authService.agent.value;
    event.date = firebase.firestore.FieldValue.serverTimestamp();
    if (this.jobsService.getCurrentJob()) {
      event.account_id = this.jobsService.getCurrentJob().account_id;
    }
    event.state = EventState.pending;
    event.cssClass = EventCssClass.agent;
    console.log('Sending event', event);
    this.event.emit(event);
  }

  resetMessageInputForm(text: string = null) {
    if (this.actionsDefaultFooter) {
      this.actionsDefaultFooter.resetForm(text);
    }
  }

  onAiSuggestedAnswer(event: { question_id: string; answer: string }) {
    const question = this.convsService.getCurrentConversationQuestionByQuestionId(event.question_id);

    if (question.controlType === 'number') {
      question.response = +event.answer;
    } else {
      question.response = event.answer;
    }

    this.convsService.updateConversationQuestion(this.conversation, question);
    this.checkForTemplateResponse(question, event.answer);
  }

  onAiSuggestedTemplate(templateId: string) {
    const tmpl = this.jobsService.getTemplateById(templateId);
    if (tmpl) {
      this.onSelectedTemplate(tmpl);
    }
  }

  onSelectedTemplate(template: Template) {
    const text = this.templateService.buildMessage(template, this.conversation.lead, this.authService.agent.value);
    this.currentlyDisplayedComponent = 'default';
    this.cd.detectChanges();

    this.actionsDefaultFooter.injectTemplate(text, template.media);
  }

  private isDifferentConversation(changes: SimpleChanges): boolean {
    if (!changes.conversation || !changes.conversation.previousValue) {
      return true;
    }
    return changes.conversation.previousValue.id !== changes.conversation.currentValue.id;
  }

  private checkForTemplateResponse(q: QuestionBase<any>, value) {
    let tmplId = q.template;
    if (q.controlType === 'select') {
      const resps = (q as SelectQuestion).responses.filter(r => r.text === value);
      if (resps.length > 0 && resps[0].template) {
        tmplId = resps[0].template;
      } else {
        tmplId = '';
      }
    }

    if (tmplId) {
      const tmpl = this.jobsService.getTemplateById(tmplId);
      if (tmpl) {
        this.onSelectedTemplate(tmpl);
      }
    }
  }
}
