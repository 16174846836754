import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Job } from '../models/job';
import { AuthService } from '../services/auth.service';
import { JobsService } from '../services/jobs.service';

@Injectable({
  providedIn: 'root'
})
export class AssignmentGuard implements CanActivate {
  constructor(private authService: AuthService, private jobsService: JobsService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.agent$.pipe(
      switchMap(agent => {
        // This should not happen since the auth guard should catch this first
        if (!agent) {
          return of(false);
        }

        // Check for agent job assignments
        return this.jobsService.loaded$.pipe(
          filter(loaded => !!loaded),
          switchMap(() => this.jobsService.availableJobs$),
          map((jobs: Job[]) => {
            if (!jobs || jobs.length === 0) {
              console.log('No jobs detected in jobs guard, routing to pending');
              return this.router.createUrlTree(['accounts', next.params.accountId, 'pending']);
            }
            return true;
          })
        );
      })
    );
  }
}
