import { Component, Input } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { ConvsService } from 'src/app/services/convs.service';
import { DomainService } from 'src/app/services/domain.service';
import { LanguageService } from 'src/app/services/language.service';
import { SignoutService } from 'src/app/services/signout.service';
import { UserSettingsService } from '../services/user-settings.service';
import { combineLatest } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent {
  @Input() bgColor = 'dark';
  @Input() textColor = 'gray-700';

  agent$ = this.authService.agent$;
  currentSettings$ = this.userSettingsService.currentSettings$;
  domain$ = this.domainService.domain$;
  footerData$ = combineLatest([this.agent$, this.domain$]).pipe(
    map(([agent, domain]) => {
      if (agent && domain) {
        return { agentEmail: agent.display_email, appName: domain.app_name };
      }
      return null;
    })
  );

  isCoreDomain = this.domainService.isCoreDomain;
  showMidDot = true;
  version: string = environment.version;

  constructor(
    private authService: AuthService,
    private domainService: DomainService,
    private languageService: LanguageService,
    private signoutService: SignoutService,
    private userSettingsService: UserSettingsService
  ) {}

  onLanguageChange(lang: string) {
    this.languageService.setCurrentLanguage(lang);
  }

  onLogout() {
    this.signoutService.signout();
  }

  onThemeChange(theme) {
    this.userSettingsService.setTheme(theme);
  }

  onUiVersionChange(version) {
    this.userSettingsService.setUiVersion(+version);
  }
}
