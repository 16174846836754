import { Component, OnInit } from '@angular/core';
import { map, tap } from 'rxjs/operators';

import { AnnouncementsService } from '../services/announcements.service';
import { AuthService } from '../services/auth.service';
import { ConvsService } from '../services/convs.service';
import { SidebarService } from '../services/sidebar.service';
import { combineLatest } from 'rxjs';
import { isMobile } from '../utils/screens';

@Component({
  selector: 'app-main-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  agent$ = this.authService.agent$;
  announcements$ = this.announcementsService.currentJobAnnouncements$;
  data$ = combineLatest([this.agent$, this.announcements$]).pipe(
    map(([agent, announcements]) => ({ agent, announcements })),
    tap(() => {
      if (isMobile && this.sidebarService.initialLoad) {
        this.sidebarService.openSidebar();
      }
    })
  );

  constructor(
    private authService: AuthService,
    private announcementsService: AnnouncementsService,
    private convsService: ConvsService,
    private sidebarService: SidebarService
  ) {}

  ngOnInit() {}

  clearAnnouncements() {
    this.announcementsService.clearUnreadAnnouncements();
  }

  createNewConv() {
    this.convsService.startNewConv();
  }

  onBack() {
    this.sidebarService.openSidebar();
  }
}
