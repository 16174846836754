import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(tel) {
    const value = tel
      .toString()
      .trim()
      .replace(/^\+|-|\(|\)/g, '');

    let country;
    let areacode;
    let linenumber;

    switch (value.length) {
      case 10: // +1PPP####### -> C (PPP) ###-####
        country = '';
        areacode = value.slice(0, 3);
        linenumber = value.slice(3);
        break;

      case 11: // +CPPP####### -> CCC (PP) ###-####
        country = value[0] === '1' ? '' : value[0] + ' ';
        areacode = value.slice(1, 4);
        linenumber = value.slice(4);
        break;

      case 12: // +CCCPP####### -> CCC (PP) ###-####
        country = value.slice(0, 3) + ' ';
        areacode = value.slice(3, 5);
        linenumber = value.slice(5);
        break;

      default:
        return tel;
    }

    linenumber = linenumber.slice(0, 3) + '-' + linenumber.slice(3);

    return `${country}(${areacode}) ${linenumber}`.trim();
  }
}
