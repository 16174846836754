import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { filter, map } from 'rxjs/operators';

import { Conversation } from '../models/conversation';
import { ConvsService } from '../services/convs.service';
import { Lead } from '../models/leads';
import { Observable } from 'rxjs';
import { Template } from '../models/job';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-conv-header',
  templateUrl: './conv-header.component.html',
  styleUrls: ['./conv-header.component.scss']
})
export class ConvHeaderComponent implements OnInit {
  @Input() isExpanded = false;
  @Output() templateSelected = new EventEmitter<Template>();

  @ViewChild('headerTabs') private headerTabs: NgbNav;

  lead$: Observable<Lead> = this.convsService.currentConversation$.pipe(
    filter(Boolean),
    map((conv: Conversation) => conv.lead)
  );
  leadTabData$ = this.lead$.pipe(
    map(lead => {
      const auxData = this.getAuxData(lead);
      return { lead, auxData };
    })
  );

  activeTab;

  constructor(private convsService: ConvsService) {}

  ngOnInit() {}

  colapseHeader() {
    this.isExpanded = false;
    this.headerTabs.select('details');
  }

  onTemplateSelection(t: Template) {
    this.templateSelected.emit(t);
    this.colapseHeader();
  }

  private getAuxData(lead: Lead): string[] {
    const auxData = [];
    if (lead.extern_id) {
      auxData.push(lead.extern_id);
    }
    if (lead.aux_data1) {
      auxData.push(lead.aux_data1);
    }
    if (lead.aux_data2) {
      auxData.push(lead.aux_data2);
    }
    if (lead.aux_data3) {
      auxData.push(lead.aux_data3);
    }
    if (lead.aux_data4) {
      auxData.push(lead.aux_data4);
    }
    if (lead.aux_data5) {
      auxData.push(lead.aux_data5);
    }
    return auxData;
  }
}
