import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TestJobsService {
  private _testJobId = new BehaviorSubject<string>(null);

  constructor() {
    this.checkStorageForTestJob();
  }

  get isTestJob(): boolean {
    return !!this._testJobId.value;
  }

  get isTestJob$() {
    return this._testJobId.asObservable().pipe(map(id => !!id));
  }

  get testJobId(): string {
    return this._testJobId.value;
  }

  get testJobId$() {
    return this._testJobId.asObservable();
  }

  clearTestJob(): void {
    localStorage.removeItem('testJobId');
    this._testJobId.next(null);
  }

  setTestJob(testJobId: string): void {
    localStorage.setItem('testJobId', testJobId);
    this._testJobId.next(testJobId);
  }

  signOut(): void {
    this.clearTestJob();
  }

  private checkStorageForTestJob(): void {
    console.log('Checking storage for test job');
    const testJobId = localStorage.getItem('testJobId');
    if (testJobId) {
      console.log('Test job id found in storage', testJobId);
      this._testJobId.next(testJobId);
    }
  }
}
