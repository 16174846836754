import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RedactedService {
  shouldRedact = false;

  constructor(private authService: AuthService) {
    this.subscribeToCurrentAgent();
  }

  // Currently this only works for Global agents but we can add domain based or job based redaction later
  subscribeToCurrentAgent() {
    this.authService.agent$.subscribe(agent => {
      if (agent.aid === 'GLOBAL') {
        this.shouldRedact = true;
      } else {
        this.shouldRedact = false;
      }
    });
  }
}
