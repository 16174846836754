import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { Alert } from '../models/alert';
import { DomainService } from './domain.service';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  messages: Alert[] = [];
  systemMessage: Alert = {} as Alert;

  constructor(private domainService: DomainService) {
    this.clearAfter = this.clearAfter.bind(this);
    this.subscribeToSystemMessages();
  }

  setMessage(text: string, params: object = {}, alertType: string = 'danger', clearAfter: number = null): string {
    if (this.messages.length > 4) {
      this.messages = this.messages.slice(-4);
    }

    const alert = {
      id: uuidv4(),
      text,
      params,
      alert_type: alertType
    };
    this.messages.push(alert);

    if (clearAfter) {
      this.clearAfter(alert.id, clearAfter);
    }

    return alert.id;
  }

  clearMessage(id: string) {
    this.messages = this.messages.filter(m => m.id !== id);
  }

  clearAllMessages() {
    this.messages = [];
  }

  signOut() {
    this.clearAllMessages();
    console.log('Alert service signed out');
  }

  private subscribeToSystemMessages() {
    this.domainService.systemConfigs$.pipe(map(config => config.system_message)).subscribe(system_message => {
      this.systemMessage = system_message || ({} as Alert);
    });
  }

  private clearAfter(id: string, ms: number) {
    setTimeout(() => {
      this.clearMessage(id);
    }, ms);
  }
}
