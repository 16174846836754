import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  defaultLanguage = 'en';
  agentPreferredLanguage: string;
  agentUida: string;
  currentLanguage = new BehaviorSubject<string>(this.defaultLanguage);
  currentLanguage$ = this.currentLanguage.asObservable();
  supportedLanguages = ['en', 'fr-CA', 'es-MX'];

  constructor(private translate: TranslateService) {
    this.init();
  }

  getCurrentLanguage() {
    return this.currentLanguage.value;
  }

  getDefaultLanguage() {
    return this.defaultLanguage;
  }

  setCurrentLanguage(lang: string) {
    if (!lang) {
      return;
    }

    this.translate.use(lang);
    this.currentLanguage.next(lang);
  }

  private init() {
    this.translate.addLangs(this.supportedLanguages);
    this.translate.setDefaultLang(this.defaultLanguage);

    const browserLang = this.translate.getBrowserLang();
    if (browserLang.startsWith('fr')) {
      this.setCurrentLanguage('fr-CA');
    } else if (browserLang.startsWith('es')) {
      this.setCurrentLanguage('es-MX');
    }
  }
}
