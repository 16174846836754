import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../services/auth.service';
import { DomainService } from '../services/domain.service';

@Component({
  selector: 'app-request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.scss']
})
export class RequestPasswordResetComponent implements OnInit {
  domain$ = this.domainService.domain$;
  error = false;
  form: FormGroup;
  submitting = false;
  success = false;

  constructor(private auth: AuthService, private domainService: DomainService, private fb: FormBuilder) {
    this.form = this.fb.group({
      email: [null, Validators.required]
    });
  }

  ngOnInit(): void {}

  onSubmit(accountId: string) {
    console.table(this.form.value);
    this.submitting = true;
    const { email } = this.form.value;
    const rootDomain = this.domainService.getRootDomain();
    this.auth
      .sendPasswordResetEmail(email, accountId, rootDomain)
      .then(() => {
        this.success = true;
      })
      .catch(error => {
        console.error(error.code);
        if (error.code === 'internal' || error.code === 'unknown') {
          this.error = true;
        } else {
          this.success = true;
        }
      })
      .finally(() => {
        this.submitting = false;
      });
  }
}
