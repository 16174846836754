import * as firebase from 'firebase/app';

import { Observable, from } from 'rxjs';

import { AngularFirestore } from '@angular/fire/firestore';
import { DNCRequest } from '../models/dnc';
import { Injectable } from '@angular/core';
import { Lead } from '../models/leads';
import { OptOutEvent } from '../models/event';
import { TimeService } from './time.service';
import { map } from 'rxjs/operators';
import { timezones } from '../utils/timezones';
import { utcToZonedTime } from 'date-fns-tz';
import { Conversation } from '../models/conversation';
import { Job } from '../models/job';

@Injectable({
  providedIn: 'root'
})
export class LeadsService {
  optOutLeadCloudFunction = firebase.functions().httpsCallable('conversations-optOutLead');

  constructor(private afs: AngularFirestore, private timeService: TimeService) {}

  optOutLead(event: OptOutEvent, conv: Conversation, job: Job): Observable<OptOutEvent> {
    return from(
      this.optOutLeadCloudFunction({
        event,
        account_id: job.base_account_id,
        identity_id: job.account_id,
        job_id: job.id,
        conversation_id: conv.id,
        lead: conv.lead,
        last_reply_message: conv.lastEventText
      })
      // this.afs.collection<DNCRequest>('dncRequest').add({
      //   phone: event.phone,
      //   account_id: event.account_id,
      //   conversation_id: event.conversation_id
      // })
    ).pipe(map(() => event));
  }

  setLocalTime(lead: Lead) {
    try {
      lead.localtime = utcToZonedTime(this.timeService.now, timezones[lead.timezone]);
    } catch (error) {
      console.warn('Could not convert lead timezone', { error });
      lead.localtime = this.timeService.now;
    }
    lead.isOutsideHours = this.isOutsideHours(lead.localtime);
  }

  isOutsideHours(date: Date): string {
    const hr = date.getHours();
    if (hr <= 7) {
      return 'before';
    } else if (hr >= 21) {
      return 'after';
    }
    return null;
  }
}
