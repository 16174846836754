import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AudioService {
  constructor() {}

  playDing() {
    const ding = new Audio('assets/audio/ding.mp3');
    return ding.play();
  }
}
