import { Component, OnInit } from '@angular/core';

import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DomainService } from './services/domain.service';
import { GlobalAgentService } from './services/global-agent.service';
import { LanguageService } from './services/language.service';
import { TestJobsService } from './services/test-jobs.service';
import { TimeService } from './services/time.service';
import { UserSettingsService } from './services/user-settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  data$ = this.domainService.systemConfigs$.pipe(
    map(configs => ({
      hasMinimumUI: configs.has_minimum_ui,
      minimumUI: configs.minimum_ui_version,
      currentUI: environment.version
    }))
  );

  constructor(
    private domainService: DomainService,
    public globalAgentService: GlobalAgentService,
    public languageService: LanguageService,
    public testJobsService: TestJobsService,
    public timeService: TimeService,
    public userSettingsService: UserSettingsService
  ) {}

  ngOnInit() {}
}
