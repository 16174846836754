import * as firebase from 'firebase/app';

import { AngularFirestore, DocumentChangeAction, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, from, interval, of, timer } from 'rxjs';
import {
  catchError,
  concatMap,
  delay,
  delayWhen,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  mapTo,
  mergeAll,
  retryWhen,
  scan,
  switchMap,
  switchMapTo,
  take,
  takeUntil,
  tap,
  windowTime
} from 'rxjs/operators';
import { Conversation, ConversationRequest, QuestionBase, SelectQuestion } from '../models/conversation';
import {
  CompletedEvent,
  DeferredEvent,
  Event,
  EventCssClass,
  EventState,
  EventType,
  MMSEvent,
  OptOutEvent,
  QuestionResponseEvent,
  ReopenEvent,
  TextEvent,
  TransferEvent
} from '../models/event';
import { Job, JobDisposition, JobSelectQuestionResponse } from '../models/job';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Agent } from '../models/agent';
import { Lead } from '../models/leads';
import { AlertsService } from './alerts.service';
import { AudioService } from './audio.service';
import { AuthService } from './auth.service';
import { DomainService } from './domain.service';
import { JobsService } from './jobs.service';
import { LanguageService } from './language.service';
import { LeadsService } from './leads.service';
import { ReadyIntervalService } from './ready-interval.service';
import { ResponderService } from './responder.service';
import { SkillsCheckService } from './skills-check.service';
import { SpinnerService } from './spinner.service';
import { StatsService } from './stats.service';
import { TimeService } from './time.service';

@Injectable({
  providedIn: 'root'
})
export class ConvsService {
  CONVERSATION_LIST_BATCH_SIZE = 100;

  actionableConversations = new BehaviorSubject<Conversation[]>([]);
  actionableConversations$ = this.actionableConversations.asObservable();
  private activePendingConversationsConnection = new BehaviorSubject<boolean>(false);
  allConversations = new BehaviorSubject<Conversation[]>([]);
  allConversations$ = this.allConversations.asObservable();
  errorConversations = new BehaviorSubject<Conversation[]>([]);
  errorConversations$ = this.errorConversations.asObservable();
  stopConversations = new BehaviorSubject<Conversation[]>([]);
  stopConversations$ = this.stopConversations.asObservable();
  likelyStopConversations = new BehaviorSubject<Conversation[]>([]);
  likelyStopConversations$ = this.likelyStopConversations.asObservable();
  private canSendMessages = new BehaviorSubject<boolean>(true);
  canSendMessages$ = this.canSendMessages.asObservable();
  clearTemplate$ = new BehaviorSubject<string>(null);
  private connectionErrorAlertId: string;
  currentConversation = new BehaviorSubject<Conversation>(null);
  currentConversation$ = this.currentConversation.asObservable();
  fetchingActionableDocs = false;
  fetchingAllDocs = false;
  filterDraftMessages = new BehaviorSubject(false);
  filterErrorMessages = new BehaviorSubject(true);
  inboxSortBy = new BehaviorSubject<'lastEventDate' | 'ai_scores.engagement'>('lastEventDate');
  currentFolder = new BehaviorSubject<
    'inboxtab' | 'likelystopstab' | 'alltab' | 'stopstab' | 'errortab' | 'pendingtab'
  >('inboxtab');
  finishedActionableDocs = false;
  finishedAllDocs = false;
  finishedErrorDocs = false;
  finishedStopDocs = false;
  finishedLikelyStopDocs = false;
  hasInitialized = false;
  isRoutingToNewConv = new BehaviorSubject<boolean>(false);
  lastFetchedActionableDoc: QueryDocumentSnapshot<Conversation> = null;
  lastFetchedAllDoc: QueryDocumentSnapshot<Conversation> = null;
  localTimeInterval: NodeJS.Timeout;
  makingConvRequest = new BehaviorSubject<boolean>(false);
  pendingConversations$ = new BehaviorSubject<Conversation[]>([]);
  pendingFilters: string[] = [];
  loadNextPending = false;
  sending = false;
  skillsCheckDelayTime = 2000;
  ready = new BehaviorSubject<boolean>(true);
  readyInSubject = new Subject<number>();
  readyInterval = 300;
  isFastModeEnabled = new BehaviorSubject<boolean>(false);
  isFastModeEnabled$ = this.isFastModeEnabled.asObservable().pipe(distinctUntilChanged());
  inboxSize = new BehaviorSubject<number>(this.CONVERSATION_LIST_BATCH_SIZE);
  inboxSizeRequest = new Subject<number>();
  allSize = new BehaviorSubject<number>(this.CONVERSATION_LIST_BATCH_SIZE);
  allSizeRequest = new Subject<number>();
  errorSize = new BehaviorSubject<number>(this.CONVERSATION_LIST_BATCH_SIZE);
  errorSizeRequest = new Subject<number>();
  stopSize = new BehaviorSubject<number>(this.CONVERSATION_LIST_BATCH_SIZE);
  stopSizeRequest = new Subject<number>();
  likelyStopSize = new BehaviorSubject<number>(this.CONVERSATION_LIST_BATCH_SIZE);
  likelyStopSizeRequest = new Subject<number>();
  requestConversations = new Subject<number>();
  mainSub: Subscription;
  subs: Subscription[] = [];
  showingTransmitErrorMessage = false;
  transmitRequestErrorCount = new BehaviorSubject<number>(0);
  transmitErrorIds: string[] = [];
  transmitRequestErrorTimer: NodeJS.Timeout;
  sendSignalCount = 0;
  sendSignalTimer: NodeJS.Timeout;

  requestConversationsCloudFunction = environment.staging
    ? firebase.functions().httpsCallable('conversations-requestConversationsStaging')
    : firebase.functions().httpsCallable('conversations-requestConversations');

  addConversationEventCloudFunction = firebase.functions().httpsCallable('conversations-addConversationEvent');

  updateOpenWithResponses = firebase.functions().httpsCallable('conversations-updateOpenWithResponses');

  optOutLeadCloudFunction = firebase.functions().httpsCallable('conversations-optOutLead');

  subscribeToAllConversations = this.getConversationListSubscription('all').pipe(
    catchError((err, caught) => {
      if (err.code === 'permission-denied') {
        return of([]);
      }
    }),
    map(convs => this.updateAllConversationList(convs))
  );

  subscribeToErrorConversations = this.getConversationListSubscription('error').pipe(
    catchError((err, caught) => {
      if (err.code === 'permission-denied') {
        return of([]);
      }
    }),
    map(convs => this.updateErrorConversationList(convs))
  );

  subscribeToStopConversations = this.getConversationListSubscription('stop').pipe(
    catchError((err, caught) => {
      if (err.code === 'permission-denied') {
        return of([]);
      }
    }),
    map(convs => this.updateStopConversationList(convs))
  );

  subscribeToLikelyStopConversations = this.getConversationListSubscription('likelyStop').pipe(
    catchError((err, caught) => {
      if (err.code === 'permission-denied') {
        return of([]);
      }
    }),
    map(convs => this.updateLikelyStopConversationList(convs))
  );

  constructor(
    private afs: AngularFirestore,
    private alertsService: AlertsService,
    private audioService: AudioService,
    private authService: AuthService,
    private domainService: DomainService,
    private jobsService: JobsService,
    private languageService: LanguageService,
    private leadsService: LeadsService,
    private readyIntervalService: ReadyIntervalService,
    private responderService: ResponderService,
    private router: Router,
    private skillsCheckService: SkillsCheckService,
    private spinnerService: SpinnerService,
    private statsService: StatsService,
    private timeService: TimeService
  ) {
    let currentAgent: Agent = null;
    this.authService.agent$.pipe(map(agent => agent as Agent)).subscribe((agent: Agent) => {
      if (agent === null) {
        if (this.mainSub) {
          this.mainSub.unsubscribe();
        }
        currentAgent = null;
        return;
      } else if (currentAgent && currentAgent.id === agent.id) {
        return;
      }

      currentAgent = agent;
      this.initData(agent);
    });

    this.incrementSendSignalCount = this.incrementSendSignalCount.bind(this);
    this.resetSendSignalCount = this.resetSendSignalCount.bind(this);
    this.subscribeToTransmitErrors = this.subscribeToTransmitErrors.bind(this);

    this.subscribeToReady();
    this.subscribeToLoadingPendingConversations();
    this.subscribeToListSizeRequests();
    this.subscribeToTransmitErrors();
    this.subscribeToCanSendMessages();
    this.subscribeToReadyInterval();
  }

  initData(agent) {
    this.hasInitialized = true;

    this.subscribeToActionableConversations();
    this.subscribeToPendingConversations();
    this.subscribeToConversationRequests();
  }

  setInboxSortBy(sortBy: 'lastEventDate' | 'ai_scores.engagement') {
    this.inboxSortBy.next(sortBy);
  }

  getCurrentFolder() {
    return this.currentFolder.value;
  }

  setCurrentFolder(folder: 'inboxtab' | 'likelystopstab' | 'alltab' | 'stopstab' | 'errortab' | 'pendingtab') {
    this.currentFolder.next(folder);
  }

  getCanSendMessages(): boolean {
    return this.canSendMessages.getValue();
  }

  subscribeToTransmitErrors() {
    this.transmitRequestErrorCount.subscribe(errorCount => {
      if (!this.showingTransmitErrorMessage && errorCount > 9) {
        this.showingTransmitErrorMessage = true;
        this.transmitErrorIds.push(this.alertsService.setMessage('alert.serviceUnavailable'));
        this.audioService.playDing();
        this.ready.next(false);
      } else if (this.showingTransmitErrorMessage && errorCount === 0) {
        this.showingTransmitErrorMessage = false;

        for (const id of this.transmitErrorIds) {
          this.alertsService.clearMessage(id);
        }

        this.transmitErrorIds = [];
        this.isFastModeEnabled.next(false);
        this.ready.next(true);
        this.transmitRequestErrorCount.next(0);
      }
    });
  }

  subscribeToListSizeRequests() {
    // Helper function to initialize size requests
    const initializeSizeRequest = (source: Subject<number>, target: BehaviorSubject<number>, label: string) => {
      source
        .pipe(
          windowTime(500),
          mergeAll(),
          filter(Boolean),
          scan((acc, _) => acc + this.CONVERSATION_LIST_BATCH_SIZE, this.CONVERSATION_LIST_BATCH_SIZE)
        )
        .subscribe(req => {
          console.log(`next ${label} size`, req);
          target.next(req);
        });
    };

    // Initialize size requests using the helper function
    initializeSizeRequest(this.inboxSizeRequest, this.inboxSize, 'inbox');
    initializeSizeRequest(this.allSizeRequest, this.allSize, 'all');
    initializeSizeRequest(this.errorSizeRequest, this.errorSize, 'error');
    initializeSizeRequest(this.stopSizeRequest, this.stopSize, 'stop');
    initializeSizeRequest(this.likelyStopSizeRequest, this.likelyStopSize, 'likely stop');
  }

  subscribeToReady() {
    this.readyInSubject
      .pipe(
        tap(() => {
          console.log('setting ready to false');
          this.ready.next(false);
        }),
        concatMap(timetodelay => of(null).pipe(delay(timetodelay)))
      )
      .subscribe(() => {
        console.log('setting ready to true');
        this.ready.next(true);
      });
  }

  subscribeToCanSendMessages() {
    combineLatest([
      this.ready,
      this.domainService.disabledByConfig$,
      this.domainService.hasMinimumUI$,
      this.isRoutingToNewConv,
      this.activePendingConversationsConnection,
      this.skillsCheckService.skillsChecked,
      this.responderService.isBuildingResponderArray
    ])
      .pipe(
        map(
          ([
            ready,
            disabledByConfig,
            hasMinimumUI,
            isRoutingToNewConv,
            activePendingConversationsConnection,
            skillsChecked,
            isBuildingResponderArray
          ]) => {
            return (
              ready &&
              !disabledByConfig &&
              hasMinimumUI &&
              !isRoutingToNewConv &&
              activePendingConversationsConnection &&
              skillsChecked &&
              !isBuildingResponderArray
            );
          }
        ),
        // Distinct until the canSendMessages value changes to avoid unnecessary emissions
        distinctUntilChanged()
      )
      .subscribe(canSend => {
        this.canSendMessages.next(canSend);
      });
  }

  startFastMode() {
    this.isFastModeEnabled.next(true);
    this.statsService.isFastModeEnabled = true;
  }

  stopFastMode() {
    this.isFastModeEnabled.next(false);
    this.statsService.isFastModeEnabled = false;
  }

  subscribeToLoadingPendingConversations() {
    const isFastModeTrue$ = this.isFastModeEnabled.pipe(
      distinctUntilChanged(),
      filter(val => val === true)
    );

    const isFastModeFalse$ = this.isFastModeEnabled.pipe(
      distinctUntilChanged(),
      filter(val => val === false)
    );

    // Stream to request more conversations
    isFastModeTrue$
      .pipe(
        switchMapTo(interval(100).pipe(takeUntil(isFastModeFalse$))),
        switchMapTo(this.pendingConversations$.pipe(take(1))),
        map(pendingConvs => pendingConvs.length),
        filter(len => len < 11 && !this.makingConvRequest.value),
        tap(len => {
          if (len === 0) {
            this.spinnerService.startSpinner('spinner.loadingConversations');
          }
          console.log('asking for 20 more leads');
          this.requestConversations.next(20);
        })
      )
      .subscribe();

    // Stream to handle pending conversations
    this.pendingConversations$.subscribe(pendingConvs => {
      if (pendingConvs.length > 0) {
        if (this.spinnerService.isSpinning) {
          this.spinnerService.stopSpinner();
        }

        if (this.loadNextPending) {
          console.log('loadNextPending was set to True. Loading next pending conv.');
          this.router.navigate([
            'accounts',
            this.authService.agent.value.aid,
            'jobs',
            this.jobsService.currentJob.value.id,
            'conv',
            pendingConvs[0].id
          ]);
          this.loadNextPending = false;
        }
      }
    });
  }

  subscribeToActionableConversations() {
    const sub = this.getConversationListSubscription('inbox')
      .pipe(
        distinctUntilChanged(),
        catchError(err => {
          if (err.code === 'permission-denied') {
            return of([]);
          }
          throw err; // Re-throw the error if it's not permission-denied
        })
      )
      .subscribe(
        (actionable: Conversation[]) => this.updateActionableConversationList(actionable),
        err => console.error('Error in actionableConversations Subscription:', err)
      );

    this.subs.push(sub);
  }

  subscribeToPendingConversations() {
    const sub = this.getConversationListSubscription('pending')
      .pipe(
        distinctUntilChanged(),
        catchError(err => {
          if (err.code === 'permission-denied') {
            return of([]);
          }
          throw err; // Re-throw the error if it's not permission-denied
        })
      )
      .subscribe(
        (pending: Conversation[]) => this.updatePendingConversationList(pending),
        err => console.error('Error in pendingConversations Subscription:', err)
      );
    this.subs.push(sub);
  }

  subscribeToConversationRequests() {
    const $agentSub = this.authService.agent.pipe(
      filter(Boolean),
      tap((agent: Agent) => console.log('ConvReq: new agent', agent))
    );

    const $jobSub = this.jobsService.currentJob.pipe(
      filter(Boolean),
      map((job: Job) => job.id),
      distinctUntilChanged(),
      tap((jobId: string) => console.log('ConvReq: new job', jobId))
    );

    const sub = combineLatest([$agentSub, $jobSub])
      .pipe(
        // Switch to a new observable when either agent or job changes
        switchMap(([agent, jid]) =>
          this.requestConversations.pipe(
            // Only proceed if no request is currently being made
            filter(cnt => !this.makingConvRequest.value),
            // Switch to handling the actual conversation request
            switchMap(cnt => this.initiateConversationRequest(agent, jid, cnt))
          )
        )
      )
      .subscribe(
        req => this.handleRequestConversationsResponse(req),
        err => this.handleRequestConversationsError(err)
      );

    this.subs.push(sub);
  }

  clearActiveTemplate(template: string) {
    this.clearTemplate$.next(template);
  }

  getConversationListSubscription(convType: 'pending' | 'inbox' | 'all' | 'stop' | 'likelyStop' | 'error' | 'draft') {
    // Determine the appropriate list size subject based on conversation type
    const listSizeSub = this.getConversationListSizeSubject(convType);

    return this.jobsService.currentJob.pipe(
      filter(Boolean),
      map(job => job as Job),
      distinctUntilKeyChanged('id'),
      // Combine job with list size and other optional parameters whenever necessary
      switchMap(job => this.combineJobWithConversationType(job, convType, listSizeSub)),
      // Use switchMap to build the Firestore query based on the conversation type
      switchMap(res => this.buildConversationListFirestoreQuery(res, convType)),
      // Retry when the connection fails with exponential backoff
      retryWhen(err => this.conversationListretryStrategy(err, convType)),
      // Cleanup error messages and reset connection status
      tap(() => this.cleanupConversationListErrorMessages(convType))
    );
  }

  processConvEvent(
    event:
      | Event
      | CompletedEvent
      | MMSEvent
      | TextEvent
      | TransferEvent
      | QuestionResponseEvent
      | OptOutEvent
      | DeferredEvent
      | ReopenEvent,
    convId: string
  ): Promise<Event | TextEvent | TransferEvent> {
    // Initialize event ID
    event.id = this.afs.createId();

    // Determine if the event requires starting a new conversation
    const isTextOrMMSEvent = event.messageType === EventType.textEvent || event.messageType === EventType.mmsEvent;
    if (isTextOrMMSEvent && (event as TextEvent | MMSEvent).thenStartNew) {
      this.incrementSendSignalCount();
    }

    // Add pending filter and set readyInSubject if necessary
    if (isTextOrMMSEvent || event.messageType === EventType.deferredEvent) {
      this.addPendingFilter(convId);
      this.readyInSubject.next(this.readyInterval);
    }

    // Prepare additional properties based on the event type
    let responderAgent = null;
    let jobIntegrations = null;
    let trackedLinks = null;

    if (this.isNonResponderEvent(event.messageType)) {
      responderAgent = this.responderService.getNextResponder();
      this.routeToNextConversationOrWelcome();
    }

    if (event.messageType === EventType.deferredEvent) {
      this.routeToNextConversationOrWelcome();
      this.alertsService.setMessage(`alert.deferred`, { number: convId }, 'info', 8000);
    }

    if (
      event.messageType === EventType.completedEvent ||
      event.messageType === EventType.optOutEvent ||
      event.messageType === EventType.reopenEvent
    ) {
      jobIntegrations = this.jobsService.getCurrentJob().integrations;
    }

    if (isTextOrMMSEvent || event.messageType === EventType.deferredEvent) {
      trackedLinks = this.jobsService.getCurrentJob().tracked_links;
    }

    // Prepare the cloud function parameters
    const isGlobalAgent = this.authService.isGlobalAgent();
    const job = this.jobsService.getCurrentJob();

    const convEventParams = {
      convId,
      event,
      responderAgent,
      jobIntegrations,
      trackedLinks,
      identityId: job.account_id,
      accountId: job.base_account_id,
      canvasserRate: isGlobalAgent ? job.canvassers_schedule?.canvasser_rate : null
    };

    return this.addConversationEventCloudFunction(convEventParams)
      .then(response => {
        const e = response.data.event as Event;

        this.statsService.performedAction(e.messageType);

        if (
          e.cssClass === EventCssClass.agent ||
          e.cssClass === EventCssClass.completed ||
          e.messageType === EventType.optOutEvent ||
          e.messageType === EventType.deferredEvent
        ) {
          this.removeConversationFromActionableList(convId);
        }

        return e;
      })
      .catch(error => {
        console.error(`Failed processing event transaction for conversation ${convId}:`, error);
        this.audioService.playDing();
        this.removeFromPendingFilter(convId);
        this.transmitRequestErrorCount.next(this.transmitRequestErrorCount.value + 1);

        setTimeout(() => {
          this.transmitRequestErrorCount.next(this.transmitRequestErrorCount.value - 1);
        }, 10000);

        return event;
      });
  }

  addQuestionResponseEventToConv(
    event: Event | QuestionResponseEvent,
    convId: string
  ): Observable<Event | TextEvent | TransferEvent> {
    event.id = this.afs.createId();

    return from(
      this.afs
        .collection<Event>(`conversations/${convId}/events`)
        .doc(event.id)
        .set(event)
    ).pipe(
      // After setting the event, update the conversation's last event context
      switchMap(() => this.updateConvLastEventContext(event, convId)),
      tap(() => {
        console.log(`Added event ${event.id} to conversation ${convId}`);
        // Mark the action in stats
        this.statsService.performedAction(event.messageType);
      }),
      // Handle errors within the observable chain
      catchError(err => {
        console.error('Error adding question response event to conversation:', err);
        return of(err);
      }),
      map(() => event) // Ensure the observable emits the event
    );
  }

  clearCurrentConversation() {
    this.router.navigate(['../../']);
    this.currentConversation.next(null);
  }

  fetchMoreActionableConversations() {
    console.log('asking for more inbox');
    this.inboxSizeRequest.next(this.CONVERSATION_LIST_BATCH_SIZE);
  }

  fetchMoreAllConversations() {
    console.log('asking for more all');
    this.allSizeRequest.next(this.CONVERSATION_LIST_BATCH_SIZE);
  }

  fetchMoreErrorConversations() {
    console.log('asking for more stop conversations');
    this.errorSizeRequest.next(this.CONVERSATION_LIST_BATCH_SIZE);
  }

  fetchMoreStopConversations() {
    console.log('asking for more stop conversations');
    this.stopSizeRequest.next(this.CONVERSATION_LIST_BATCH_SIZE);
  }

  fetchMoreLikelyStopConversations() {
    console.log('asking for more likely stop conversations');
    this.likelyStopSizeRequest.next(this.CONVERSATION_LIST_BATCH_SIZE);
  }

  getConvEvents(convId: string): Observable<Event[]> {
    return this.afs
      .collection<Event>(`conversations/${convId}/events`)
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data() as Event;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        ),
        catchError((err, caught) => {
          console.warn(`Get events error`, { error: err.code, convId });
          return of([]);
        })
      );
  }

  getCurrentConversationQuestionTextByQuestionId(id: string): string | null {
    return this.currentConversation.value?.questions.find(question => question.id === id)?.title || null;
  }

  getCurrentConversationQuestionByQuestionId(id: string): QuestionBase<string | number> | null {
    return this.currentConversation.value?.questions.find(question => question.id === id) || null;
  }

  hasDraftsRemaining(): boolean {
    return this.pendingConversations$.value?.length > 0;
  }

  removeFromPendingFilter(convId: string) {
    this.pendingFilters = this.pendingFilters.filter(id => id !== convId);
    this.updatePendingConversationList(this.pendingConversations$.value);
    console.log(`Removed from pending filter: ${convId}`);
  }

  getCurrentConversation$(id: string): Observable<Conversation> {
    if (!id) {
      this.clearCurrentConversation();
      return this.currentConversation.asObservable();
    }

    if (id !== this.currentConversation.value?.id) {
      return this.afs
        .doc<Conversation>(`conversations/${id}`)
        .valueChanges()
        .pipe(
          switchMap(conv => {
            const conversation = { id, ...conv } as Conversation;
            this.mapExistingQuestionResponses(conversation);
            this.trackLeadLocalTime(conversation.lead);
            this.currentConversation.next(conversation);
            return this.currentConversation.asObservable();
          }),
          // Handle errors and emit null if any occur
          catchError(err => {
            this.handleGetCurrentConversationError(err, id);
            return of(null);
          })
        );
    }

    return this.currentConversation.asObservable();
  }

  signOut() {
    this.subs.forEach(s => s.unsubscribe());
    this.subs = [];

    this.actionableConversations.next([]);
    this.allConversations.next([]);
    this.stopConversations.next([]);
    this.clearCurrentConversation();
    this.fetchingActionableDocs = false;
    this.fetchingAllDocs = false;
    this.finishedActionableDocs = false;
    this.finishedAllDocs = false;
    this.finishedErrorDocs = false;
    this.finishedStopDocs = false;
    this.lastFetchedActionableDoc = null;
    this.lastFetchedAllDoc = null;
    this.makingConvRequest.next(false);
    this.pendingConversations$.next([]);
    this.sending = false;

    if (this.localTimeInterval) {
      clearInterval(this.localTimeInterval);
    }

    this.hasInitialized = false;
    console.log('Conversation service signed out');
  }

  startNewConv(nextOrNew: string = 'new') {
    // currentFolder is set by the nbgNav onNavChange event when a user clicks a folder icon
    // we're doing this because we want the 'Next Conversation' button to respect the current
    // folder that is being viewed and not just use the inbox
    const desiredConversations = this.getCurrentFolderConversations();

    if (nextOrNew === 'new' && this.pendingConversations$.value.length > 0) {
      this.startNewPendingConversation();
      return;
    } else if (nextOrNew === 'next' && desiredConversations.value.length > 0) {
      this.startNextActionableConversation(desiredConversations);
      return;
    } else {
      console.log('No pending conversations available. Setting loadNextPending True');
      this.loadNextPending = true;
    }

    if (this.isFastModeEnabled.value || this.makingConvRequest.value) {
      console.log('Start new conv request when isFastModeEnabled or makingConvRequest is true');
      return;
    }

    console.log('adding request conversation');
    this.requestConversations.next(1);
  }

  // Added setter methods for future ability in case multiple things will access the sending param
  startSending() {
    this.sending = true;
  }

  // Added setter methods for future ability in case multiple things will access the sending param
  stopSending() {
    this.sending = false;
  }

  toggleFilterDraftMessages() {
    this.filterDraftMessages.next(!this.filterDraftMessages.value);
  }

  toggleFilterErrorMessages() {
    this.filterErrorMessages.next(!this.filterErrorMessages.value);
  }

  updatePendingConversationList(convs: Conversation[]) {
    const pc = convs
      .filter(c => [EventCssClass.new, EventCssClass.rehash].includes(c.state))
      .filter(c => !this.pendingFilters.includes(c.id));

    this.pendingConversations$.next(pc);
    console.log('pending conversations', convs, pc);
  }

  updateActionableConversationList(convs: Conversation[]) {
    this.finishedActionableDocs = true;
    this.actionableConversations.next(convs);
  }

  updateAllConversationList(convs: Conversation[]) {
    this.finishedAllDocs = true;
    this.allConversations.next(convs);
  }

  updateErrorConversationList(convs: Conversation[]) {
    this.finishedErrorDocs = true;
    this.errorConversations.next(convs);
  }

  updateStopConversationList(convs: Conversation[]) {
    this.finishedStopDocs = true;
    this.stopConversations.next(convs);
  }

  updateLikelyStopConversationList(convs: Conversation[]) {
    this.finishedLikelyStopDocs = true;
    this.likelyStopConversations.next(convs);
  }

  updateConvLastEventContext(
    event: Event | QuestionResponseEvent,
    convId: string
  ): Promise<Event | QuestionResponseEvent> {
    const updatePayload = {
      lastEventDate: event.date,
      lastEventText: event.text
    } as Conversation;

    if (event.cssClass !== EventCssClass.info) {
      updatePayload.state = event.cssClass;
    }

    return this.afs
      .doc<Conversation>(`conversations/${convId}`)
      .update(updatePayload)
      .then(() => event);
  }

  updateConversationQuestion(conv: Conversation, question: QuestionBase<string | number>): Observable<void> {
    const firstResponse = !conv.has_responses;
    const questionHasResponse = !!question.response;

    // Map through questions and update the matching question's response
    const payload = conv.questions.map(q => {
      if (q.id === question.id) {
        if (q.response !== question.response) {
          this.handleSelectQuestionIntegration(question as SelectQuestion);
          this.addQuestionResponseEvent(conv.id, question);
          this.jobsService.updateJobQuestion(conv.jobId, q, question);
        }
        return question;
      }
      return q;
    });

    conv.questions = payload;
    return this.updateConversationQuestions(conv.id, conv.jobId.id, payload, questionHasResponse, firstResponse);
  }

  updateConversationQuestions(
    convId: string,
    jobId: string,
    updatePayload: QuestionBase<string | number>[],
    hasResponses = false,
    firstResponse = false
  ): Observable<void> {
    const payload: Partial<Conversation> = {
      questions: updatePayload,
      has_responses: hasResponses,
      ai_suggestion: firebase.firestore.FieldValue.delete(),
      ...this.getCanvassedDispositionPayload(hasResponses)
    };

    return from(this.afs.doc<Conversation>(`conversations/${convId}`).update(payload)).pipe(
      switchMap(() => (firstResponse ? this.handleFirstResponseUpdate(jobId) : of(void 0))),
      catchError(error => {
        console.error('Failed to update conversation questions:', error);
        throw error;
      })
    );
  }

  private addPendingFilter(id: string) {
    this.pendingFilters.push(id);
    this.pendingFilters = this.pendingFilters.slice(-1000);
    this.updatePendingConversationList(this.pendingConversations$.value);
  }

  /**
   * Add question response event to the conversation.
   */
  private addQuestionResponseEvent(convId: string, question: QuestionBase<string | number>) {
    const respEvent = {
      text: `Agent marked "${question.title.slice(0, 50)}" as responded with "${question.response}"`,
      date: firebase.firestore.FieldValue.serverTimestamp(),
      state: EventState.read,
      cssClass: EventCssClass.info,
      questionId: question.id,
      questionTitle: question.title,
      questionResponse: question.response
    } as QuestionResponseEvent;
    this.addQuestionResponseEventToConv(respEvent, convId);
  }

  /**
   * Build Firestore query for conversations based on type and other parameters.
   */
  private buildConversationListFirestoreQuery(
    res: { job: Job; size: number; filterErrorMessages?: boolean; inboxSortBy?: string },
    convType: string
  ) {
    return this.afs
      .collection<Conversation>('conversations', ref => {
        const agentBaseAccountId = this.authService.uida.endsWith('GLOBAL') ? 'GLOBAL' : res.job.base_account_id;
        let query = ref
          .where('activeAgent.uida', '==', this.authService.uida)
          .where('activeAgent.aid', '==', agentBaseAccountId)
          .where('jobId', '==', this.afs.doc<Job>(`jobs/${res.job.id}`).ref);

        switch (convType) {
          case 'pending':
            query = query
              .where('state', 'in', [EventCssClass.new, EventCssClass.rehash])
              .orderBy('lastEventDate', 'asc')
              .limit(200);
            break;
          case 'inbox':
            const inboxStates = [EventCssClass.lead, EventCssClass.info, EventCssClass.success, EventCssClass.warning];
            if (!res.filterErrorMessages) {
              inboxStates.push(EventCssClass.danger);
            }
            query = query.where('state', 'in', inboxStates).orderBy(res.inboxSortBy, 'desc');
            break;
          case 'stop':
            query = query.where('state', 'in', [EventCssClass.stop]).orderBy('lastEventDate', 'desc');
            break;
          case 'likelyStop':
            query = query
              .where('state', 'in', [
                EventCssClass.lead,
                EventCssClass.info,
                EventCssClass.success,
                EventCssClass.warning
              ])
              .where('ai_scores.opt_out', '>=', 55)
              .orderBy('ai_scores.opt_out', 'desc');
            break;
          case 'error':
            query = query.where('state', 'in', [EventCssClass.danger]).orderBy('lastEventDate', 'desc');
            break;
          case 'all':
            query = query
              .where('state', 'in', [
                EventCssClass.lead,
                EventCssClass.danger,
                EventCssClass.info,
                EventCssClass.success,
                EventCssClass.warning,
                EventCssClass.agent,
                EventCssClass.completed,
                EventCssClass.stop
              ])
              .orderBy('lastEventDate', 'desc');
            break;
        }

        return query.limit(res.size);
      })
      .snapshotChanges()
      .pipe(map(this.unpackConversationData));
  }

  /**
   * Combine job with other parameters based on conversation type.
   */
  private combineJobWithConversationType(job: Job, convType: string, listSizeSub: BehaviorSubject<number>) {
    if (convType === 'inbox') {
      // reset inbox sort by every time a new job is loaded
      this.setInboxSortBy(job.ai_enabled ? 'ai_scores.engagement' : 'lastEventDate');

      return combineLatest([listSizeSub, this.filterErrorMessages, this.inboxSortBy]).pipe(
        map(([size, filterErrorMessages, inboxSortBy]) => ({ job, size, filterErrorMessages, inboxSortBy }))
      );
    }

    return listSizeSub.pipe(map(size => ({ job, size })));
  }

  /**
   * Cleanup error messages and reset connection status.
   */
  private cleanupConversationListErrorMessages(convType: string) {
    if (convType === 'pending') {
      this.setActivePendingConversationsConnection(true);
      if (this.connectionErrorAlertId) {
        this.alertsService.clearMessage(this.connectionErrorAlertId);
        delete this.connectionErrorAlertId;
        this.alertsService.setMessage('Connection restored', null, 'success', 4000);
      }
    }
  }

  /**
   * Retry strategy for when the connection fails.
   */
  private conversationListretryStrategy(err: Observable<any>, convType: string) {
    return err.pipe(
      scan((acc, error) => {
        console.error(`Failed fetching conversations from Firestore for convType ${convType}: `, error);
        return acc + 1;
      }, 0),
      map(retryCount => retryCount * 10 * 1000),
      delayWhen(delayTime => timer(delayTime)),
      tap(() => this.handleConversationListRetry(convType))
    );
  }

  /**
   * Get the payload for canvassed disposition if applicable.
   */
  private getCanvassedDispositionPayload(hasResponses: boolean): Partial<Conversation> {
    const canvassedDisposition = this.shouldAddCanvassedDisposition(hasResponses);
    return canvassedDisposition
      ? { disposition: canvassedDisposition.disposition, disposition_external_id: canvassedDisposition.external_id }
      : {};
  }

  /**
   * Get the appropriate list size subject based on conversation type.
   */
  private getConversationListSizeSubject(convType: string): BehaviorSubject<number> {
    switch (convType) {
      case 'inbox':
        return this.inboxSize;
      case 'stop':
      case 'likelyStop':
      case 'error':
      case 'draft':
        return this.stopSize;
      default:
        return this.allSize;
    }
  }

  /**
   * Get the conversations based on the current folder selection.
   */
  private getCurrentFolderConversations(): BehaviorSubject<Conversation[]> {
    switch (this.currentFolder.value) {
      case 'alltab':
        return this.allConversations;
      case 'errortab':
        return this.errorConversations;
      case 'pendingtab':
        return this.pendingConversations$;
      case 'stopstab':
        return this.stopConversations;
      case 'likelystopstab':
        return this.likelyStopConversations;
      default:
        return this.actionableConversations;
    }
  }

  /**
   * Handle account errors by setting the appropriate state and messages.
   */
  private handleAccountOrCreditsErrors(req: ConversationRequest) {
    this.readyInSubject.next(3000); // add a delay before ready for next request
    setTimeout(() => {
      this.makingConvRequest.next(false);
    }, 3000);

    if (this.pendingConversations$.value.length === 0) {
      this.stopFastMode();
    }
  }

  /**
   * Handle retries by disabling/enabling UI and displaying messages as needed.
   */
  private handleConversationListRetry(convType: string) {
    if (convType === 'pending') {
      this.setActivePendingConversationsConnection(false);
      if (!this.connectionErrorAlertId) {
        this.audioService.playDing();
        this.connectionErrorAlertId = this.alertsService.setMessage(`Connection error. Attempting to reconnect...`);
      }
    }
  }

  /**
   * Handle the first response update logic.
   */
  private handleFirstResponseUpdate(jobId: string): Observable<void> {
    console.log('Updating open with responses');
    const agent = this.authService.getAgent();
    return from(
      this.updateOpenWithResponses({
        agentId: agent.id,
        jobId,
        accountId: agent.aid,
        value: 1
      }).catch(error => {
        console.error('Failed updating open with response', error);
        // Re-throw the error to ensure the Observable chain handles it
        throw error;
      })
    ).pipe(
      tap(resp => console.log(`Finished upsert open_with_responses`, resp)),
      mapTo(undefined)
    );
  }

  /**
   * Handle conversation retrieval errors.
   */
  private handleGetCurrentConversationError(err: any, convId: string) {
    if (err.code === 'permission-denied') {
      console.warn(`Permission denied getting conversation ${convId}`);
    } else {
      console.error(`Error getting conversation ${convId}`, { error: err.code });
      this.alertsService.setMessage("We can't find that conversation. Something went wrong.");
    }
    this.clearCurrentConversation();
  }

  /**
   * Handle request error.
   */
  private handleRequestConversationsError(err: any) {
    this.audioService.playDing();
    if (err === 'timeout') {
      this.alertsService.setMessage('alert.timeout');
    } else {
      this.alertsService.setMessage('alert.unknownError', { error: err });
    }
    console.error('ConvReq: ERROR - Failed due to unknown reason.', err);

    this.makingConvRequest.next(false);

    if (this.spinnerService.isSpinning) {
      this.spinnerService.stopSpinner();
    }
  }

  /**
   * Handle successful request response.
   */
  private handleRequestConversationsResponse(req: ConversationRequest) {
    if (this.spinnerService.isSpinning) {
      this.spinnerService.stopSpinner();
    }

    // Check if the request was successful
    if (req.state === 'created') {
      this.makingConvRequest.next(false);
      console.log('ConvReq:', req.id, 'done');
      return;
    }

    // 403 errors are leads and account errors.
    // 429 errors are out of unlimited hour tokens.
    // 420 errors are out of unlimited daily credits.
    // We are adding a delay here so the agent hopefully doesn't continue
    // to spam Rogue Leader when they can't do anything.
    if ([403, 420, 429].includes(req.errno)) {
      this.handleAccountOrCreditsErrors(req);
    } else {
      this.makingConvRequest.next(false);
    }

    console.log('ConvReq:', req.id, `New conversation failed to be created: (${req.errno}) ${req.errmsg}`);
    this.audioService.playDing();
    this.setRequestConversationsAlert(req.errno, req.errmsg);
  }

  /**
   * Handle integration details for select questions.
   */
  private handleSelectQuestionIntegration(question: SelectQuestion) {
    if (question.controlType === 'select' && question.integration_details) {
      console.log('Processing integration select question');
      const resp = question.responses.find(r => r.text === question.response);
      if (resp?.integration_details) {
        console.log('Has response integration');
        question.response_external_id = resp.integration_details.external_id;
      } else if (question.response_external_id && !resp) {
        question.response_external_id = null;
      }
    }
  }

  private incrementSendSignalCount() {
    this.sendSignalCount += 1;

    if (this.sendSignalCount > 2) {
      this.startFastMode();
    }

    if (this.sendSignalTimer) {
      clearTimeout(this.sendSignalTimer);
    }
    this.sendSignalTimer = setTimeout(this.resetSendSignalCount, 2000);
  }

  /**
   * Initiate the conversation request.
   */
  private initiateConversationRequest(agent: Agent, jid: string, cnt: number): Observable<ConversationRequest> {
    this.makingConvRequest.next(true);
    this.spinnerService.startSpinner('spinner.loadingConversations');

    const request: ConversationRequest = {
      id: this.afs.createId(),
      jobId: jid,
      agent,
      state: 'new',
      count: cnt,
      ui_version: environment.version,
      ui_language: this.languageService.getCurrentLanguage(),
      phone: agent.phone || null
    };

    console.log(`ConvReq: ${request.id} -- ${agent.display_name} requested for ${cnt} convs for job ${jid}`);
    const startRequestTime = this.timeService.now.getTime();

    return from(this.requestConversationsCloudFunction({ request })).pipe(
      take(1),
      tap(() => this.logRequestConversationsTime(startRequestTime)),
      map(result => result.data as ConversationRequest),
      catchError(err => {
        console.error('requestConversationsCloudFunction failed:', err);
        throw err;
      })
    );
  }

  private isNonResponderEvent(eventType: EventType): boolean {
    return (
      (eventType === EventType.textEvent || eventType === EventType.mmsEvent) &&
      !this.responderService.currentAgentIsResponder.value
    );
  }

  /**
   * Log the request time duration.
   */
  private logRequestConversationsTime(startRequestTime: number) {
    const endRequestTime = this.timeService.now.getTime();
    console.log(`ConvReq took ${(endRequestTime - startRequestTime) / 1000}s`);
  }

  private mapExistingQuestionResponses(c: Conversation) {
    if (c.questions) {
      c.questions = c.questions.map(q => {
        if (q.controlType !== 'select') {
          return q;
        }

        const sq: SelectQuestion = q as SelectQuestion;

        if (sq.responses && sq.responses.length > 0) {
          return sq;
        } else if (sq.options === undefined || sq.options === null || sq.options.length === 0) {
          return sq;
        }

        sq.responses = sq.options.map(o => {
          return {
            id: '',
            template: '',
            text: o
          } as JobSelectQuestionResponse;
        });

        return sq;
      });
    }
  }

  // Checks the current job to see if its a VAN job and should add the Canvassed disposition
  // when a question has been answered.
  private removeConversationFromActionableList(convId: string) {
    const actionables = this.actionableConversations.value || [];
    this.actionableConversations.next(actionables.filter(c => c.id !== convId));
  }

  private resetSendSignalCount() {
    this.sendSignalCount = 0;
    this.stopFastMode();
  }

  private routeToNextConversationOrWelcome() {
    if (!this.isFastModeEnabled.value && this.pendingConversations$.value.length > 0) {
      this.startNewConv();
    } else {
      this.router.navigate([
        'accounts',
        this.authService.agent.value.aid,
        'jobs',
        this.jobsService.currentJob.value.id
      ]);
    }
  }

  private setActivePendingConversationsConnection(value: boolean) {
    if (this.activePendingConversationsConnection.value !== value) {
      this.activePendingConversationsConnection.next(value);
    }
  }

  /**
   * Set an appropriate alert based on the error number.
   */
  private setRequestConversationsAlert(errno: number, errmsg: string) {
    let alertMessage: string;

    switch (errno) {
      case 429:
        alertMessage = 'alert.unlimitedLimit';
        break;
      case 420:
        alertMessage = 'alert.unlimitedDailyLimit';
        break;
      default:
        alertMessage = 'alert.failedNewConversation';
        this.alertsService.setMessage(alertMessage, {
          errno: errno || 500,
          errmsg: errmsg || 'Internal Error'
        });
        return;
    }

    this.alertsService.setMessage(alertMessage, undefined, 'info');
  }

  private shouldAddCanvassedDisposition(hasResponses: boolean): JobDisposition {
    if (!hasResponses) {
      return null;
    }

    let disposition = null;
    const job = this.jobsService.getCurrentJob();
    if (job.integrations && job.dispositions && job.integrations.findIndex(i => i.thirdparty === 'van') > -1) {
      disposition = job.dispositions.find(d => d.disposition === 'Canvassed');
    }
    return disposition;
  }

  private subscribeToReadyInterval() {
    this.readyIntervalService.readyInterval$.subscribe((readyInterval: number) => {
      this.readyInterval = readyInterval;
    });
  }

  /**
   * Start a new pending conversation if available.
   */
  private startNewPendingConversation() {
    console.log('Agent already has a pending conversation available for start new');

    // On some slow computers routing to a new conversation was taking longer than
    // the delay interval between messages. This was causing the startNewConv
    // function to loop routing to the "next" conversation and not actually ever
    // complete the route.
    // I am adding this isRouting subject to the "ready" check so a new conversation
    // request cannot be started if there is already one in progress.
    this.isRoutingToNewConv.next(true);
    this.router
      .navigate([
        'accounts',
        this.authService.agent.value.aid,
        'jobs',
        this.jobsService.currentJob.value.id,
        'conv',
        this.pendingConversations$.value[0].id
      ])
      .finally(() => {
        this.isRoutingToNewConv.next(false);
      });
  }

  /**
   * Start the next available actionable conversation.
   */
  private startNextActionableConversation(desiredConversations: BehaviorSubject<Conversation[]>) {
    // On some slow computers routing to a new conversation was taking longer than
    // the delay interval between messages. This was causing the startNewConv
    // function to loop routing to the "next" conversation and not actually ever
    // complete the route.
    // I am adding this isRouting subject to the "ready" check so a new conversation
    // request cannot be started if there is already one in progress.
    this.isRoutingToNewConv.next(true);

    // Find the index of the next conversation to route to
    let nextIndex = desiredConversations.value.findIndex(c => this.currentConversation.value.id === c.id) + 1;
    if (nextIndex >= desiredConversations.value.length) {
      nextIndex = 0;
    }

    this.router
      .navigate([
        'accounts',
        this.authService.agent.value.aid,
        'jobs',
        this.jobsService.currentJob.value.id,
        'conv',
        desiredConversations.value[nextIndex].id
      ])
      .finally(() => {
        this.isRoutingToNewConv.next(false);
      });
  }

  private trackLeadLocalTime(lead: Lead) {
    if (lead && lead.timezone) {
      this.leadsService.setLocalTime(lead);
      if (this.localTimeInterval) {
        clearInterval(this.localTimeInterval);
      }
      this.localTimeInterval = setInterval(() => this.leadsService.setLocalTime(lead), 1000);
    }
  }

  private unpackConversationData(actions: DocumentChangeAction<Conversation>[]): Conversation[] {
    return actions.map(a => {
      const data = a.payload.doc.data();
      const id = a.payload.doc.id;
      return { id, ...data } as Conversation;
    });
  }
}
