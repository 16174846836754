import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { DomainService } from '../services/domain.service';

@Injectable({
  providedIn: 'root'
})
export class AccountGuard implements CanActivate {
  constructor(private domainService: DomainService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.domainService.domain$.pipe(
      switchMap(domain => {
        if (domain) {
          return of(true);
        }
        console.log('Domain not set');
        return this.domainService
          .fetchDomainData(this.domainService.getAccountDomainString(next.params.accountId))
          .pipe(
            map(fetchedDomain => {
              console.log('Got a domain this time', fetchedDomain);
              return true;
            }),
            catchError(() => {
              console.log('Still no domain');
              return of(
                this.router.createUrlTree([''], {
                  queryParams: { redirect: state.url }
                })
              );
            }),
            first()
          );
      })
    );
  }
}
