import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  initialLoad = true;
  sidebarIsClosed$ = new BehaviorSubject<boolean>(true);

  constructor() {}

  toggleSidebar() {
    this.sidebarIsClosed$.next(!this.sidebarIsClosed$.value);
  }

  openSidebar() {
    this.sidebarIsClosed$.next(false);
  }

  closeSidebar() {
    this.sidebarIsClosed$.next(true);
  }
}
