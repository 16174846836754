import { Injectable } from '@angular/core';
import { Agent } from '../models/agent';
import { Template } from '../models/job';
import { Lead } from '../models/leads';

interface MessageOptions {
  [key: string]: number;
}

const HI_OPTIONS: MessageOptions = {
  Hi: 3,
  Hey: 3,
  Hello: 3,
  Greetings: 1,
  'Hi there': 1,
  'Hey there': 1,
  'Hello there': 1,
  null: 2
};

const HI_OPTIONS_MINIMIZED = {
  Hi: 3,
  Hey: 3,
  Hello: 3,
  null: 2
};

const AGENT_IDENTIFICATION_OPTIONS: MessageOptions = {
  'This is {agent_first_name}': 2,
  'My name is {agent_first_name}': 1,
  'I am {agent_first_name}': 3,
  '{agent_first_name} here': 1
};
const COMPANY_IDENTIFICATION_OPTIONS: MessageOptions = {
  'w/ {company_name}.': 1,
  'with {company_name}.': 1,
  'for {company_name}.': 1
};
const STOP_OPTIONS: MessageOptions = {
  'STOP 2 Stop.': 4,
  'STOP to Stop.': 4,
  'Txt STOP 2 Stop.': 1,
  'Txt STOP to Stop.': 1,
  'Reply STOP 2 Stop.': 1,
  'Reply STOP to Stop.': 1,
  'STOP 2 Optout.': 2,
  'STOP to Optout.': 2,
  'Txt STOP 2 Optout.': 1,
  'Txt STOP to Optout.': 1,
  'Reply STOP 2 Optout.': 1,
  'Reply STOP to Optout.': 1,
  '2 optout reply STOP.': 1,
  '2 optout txt STOP.': 1,
  '2 stop reply STOP.': 1,
  '2 stop txt STOP.': 1,
  'To optout reply STOP.': 1,
  'To optout txt STOP.': 1,
  'To stop reply STOP.': 1,
  'To stop txt STOP.': 1,
  'Stop w/ STOP.': 2,
  'Optout w/ STOP.': 2,
  '2 end msgs, STOP.': 1,
  '2 end messages, STOP.': 1,
  'To end msgs, STOP.': 1,
  'To end messages, STOP.': 1,
  'STOP 2 end msgs.': 1,
  'STOP 2 end messages.': 1,
  'STOP to end msgs.': 1,
  'STOP to end messages.': 1
};

const STOP_OPTIONS_MINIMIZED = {
  Stop2End: 1,
  STOP2End: 1,
  STOP2END: 1,
  stop2end: 1,
  STOP2end: 1,
  'Stop=End': 1,
  'STOP=End': 1,
  'STOP=END': 1,
  'stop=end': 1,
  'STOP=end': 1,
  StoptoEnd: 1,
  STOPtoEnd: 1,
  STOPtoEND: 1,
  stoptoend: 1,
  STOPtoend: 1,
  StopToEnd: 1,
  STOPToEnd: 1,
  STOPToEND: 1,
  stopToend: 1,
  STOPToend: 1,
  Stop2Stop: 1,
  STOP2Stop: 1,
  STOP2STOP: 1,
  stop2stop: 1,
  STOP2stop: 1,
  'Stop=Stop': 1,
  'STOP=Stop': 1,
  'STOP=STOP': 1,
  'stop=stop': 1,
  'STOP=stop': 1,
  StoptoStop: 1,
  STOPtoStop: 1,
  STOPtoSTOP: 1,
  stoptostop: 1,
  STOPtostop: 1,
  StopToStop: 1,
  STOPToStop: 1,
  STOPToSTOP: 1,
  stopTostop: 1,
  STOPTostop: 1,
  Stop2Quit: 1,
  STOP2Quit: 1,
  STOP2QUIT: 1,
  stop2quit: 1,
  STOP2quit: 1,
  'Stop=Quit': 1,
  'STOP=Quit': 1,
  'STOP=QUIT': 1,
  'stop=quit': 1,
  'STOP=quit': 1,
  StoptoQuit: 1,
  STOPtoQuit: 1,
  STOPtoQUIT: 1,
  stoptoquit: 1,
  STOPtoquit: 1,
  StopToQuit: 1,
  STOPToQuit: 1,
  STOPToQUIT: 1,
  stopToquit: 1,
  STOPToquit: 1,
  'Stop 2End': 1,
  'STOP 2End': 1,
  'STOP 2END': 1,
  'stop 2end': 1,
  'STOP 2end': 1,
  'Stop =End': 1,
  'STOP =End': 1,
  'STOP =END': 1,
  'stop =end': 1,
  'STOP =end': 1,
  'Stop toEnd': 1,
  'STOP toEnd': 1,
  'STOP toEND': 1,
  'stop toend': 1,
  'STOP toend': 1,
  'Stop ToEnd': 1,
  'STOP ToEnd': 1,
  'STOP ToEND': 1,
  'stop Toend': 1,
  'STOP Toend': 1,
  'Stop2 End': 1,
  'STOP2 End': 1,
  'STOP2 END': 1,
  'stop2 end': 1,
  'STOP2 end': 1,
  'Stop= End': 1,
  'STOP= End': 1,
  'STOP= END': 1,
  'stop= end': 1,
  'STOP= end': 1,
  'Stopto End': 1,
  'STOPto End': 1,
  'STOPto END': 1,
  'stopto end': 1,
  'STOPto end': 1,
  'StopTo End': 1,
  'STOPTo End': 1,
  'STOPTo END': 1,
  'stopTo end': 1,
  'STOPTo end': 1
};

const HI_PUNCT_OPTIONS: MessageOptions = { '.': 3, '!': 3, '!!': 2 };

const HI_PUNCT_OPTIONS_MINIMIZED = { '.': 1, '!': 1 };

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  hiOptions: string[];
  hiOptionsMinimized: string[];
  agentIdentificationOptions: string[];
  companyIdentificationOptions: string[];
  stopOptions: string[];
  stopOptionsMinimized: string[];
  punctOptions: string[];
  punctOptionsMinimized: string[];

  constructor() {
    this.initHiOptions();
    this.initHiOptionsMinimized();
    this.initAgentIdentificationOptions();
    this.initCompanyIdentificationOptions();
    this.initStopOptions();
    this.initStopOptionsMinimized();
    this.initPunctOptions();
    this.initPunctOptionsMinimized();
  }

  buildMessage(template: Template, lead: Lead, agent: Agent): string {
    if (template.advanced) {
      return this.buildAdvancedTemplate(
        lead,
        agent,
        template.advanced.organization,
        template.advanced.bodies,
        template.advanced.call_to_actions,
        template.advanced.show_stop,
        template.advanced.minimized
      );
    }
    return this.substituteTemplateVariables(template.text, lead, agent);
  }

  private buildAdvancedTemplate(
    lead: Lead,
    agent: Agent,
    companyName: string,
    bodyOptions: string[],
    ctaOptions: string[],
    showStop: boolean = true,
    minimized: boolean = false
  ): string {
    let msg = '';

    // Build the Hi statement
    const hi = this.buildHi(this.sanitizeFirstName(lead.first_name), minimized);
    if (hi) {
      msg += `${hi} `;
    }

    // Build the Agent identification statement
    if (!minimized && agent.first_name) {
      const agentIdentification = this.buildAgentIdentification(agent.first_name, companyName);
      if (agentIdentification) {
        msg += `${agentIdentification} `;
      }
    }

    // Build message body
    const body = this.buildBody(bodyOptions);
    if (body) {
      msg += `${body} `;
    }

    // Build CTA message
    const cta = this.buildCta(ctaOptions);
    if (cta) {
      msg += `${cta} `;
    }

    if (showStop) {
      const stop = this.buildStop(minimized);
      if (stop) {
        msg += stop;
      }
    }

    return this.substituteTemplateVariables(msg, lead, agent);
  }

  private buildStop(minimized: boolean): string {
    return minimized ? this.getRandom(this.stopOptionsMinimized) : this.getRandom(this.stopOptions);
  }

  private buildCta(ctaOptions: string[]): string {
    return this.trimWhitespace(this.getRandom(ctaOptions));
  }

  private buildBody(bodyOptions: string[]): string {
    return this.trimWhitespace(this.getRandom(bodyOptions));
  }

  private buildAgentIdentification(firstName: string, companyName: string): string {
    const agentChoice = this.getRandom(this.agentIdentificationOptions);
    const agentIdent = agentChoice.replace('{agent_first_name}', firstName);

    const companyChoice = this.getRandom(this.companyIdentificationOptions);
    const companyIdent = companyChoice.replace('{company_name}', companyName);

    return `${agentIdent} ${companyIdent}`;
  }

  private buildHi(firstName: string, minimized: boolean): string {
    let hi = minimized ? this.getRandom(this.hiOptionsMinimized) : this.getRandom(this.hiOptions);
    if (!hi || hi === 'null') {
      return null;
    }

    if (firstName) {
      hi += `, ${firstName}`;
    }

    const punct = minimized ? this.getRandom(this.punctOptionsMinimized) : this.getRandom(this.punctOptions);

    return (hi += punct);
  }

  private initHiOptions() {
    this.hiOptions = this.generateWeightedOptions(HI_OPTIONS);
  }

  private initHiOptionsMinimized() {
    this.hiOptionsMinimized = this.generateWeightedOptions(HI_OPTIONS_MINIMIZED);
  }

  private initAgentIdentificationOptions() {
    this.agentIdentificationOptions = this.generateWeightedOptions(AGENT_IDENTIFICATION_OPTIONS);
  }

  private initCompanyIdentificationOptions() {
    this.companyIdentificationOptions = this.generateWeightedOptions(COMPANY_IDENTIFICATION_OPTIONS);
  }

  private initStopOptions() {
    this.stopOptions = this.generateWeightedOptions(STOP_OPTIONS);
  }

  private initStopOptionsMinimized() {
    this.stopOptionsMinimized = this.generateWeightedOptions(STOP_OPTIONS_MINIMIZED);
  }

  private initPunctOptions() {
    this.punctOptions = this.generateWeightedOptions(HI_PUNCT_OPTIONS);
  }

  private initPunctOptionsMinimized() {
    this.punctOptionsMinimized = this.generateWeightedOptions(HI_PUNCT_OPTIONS_MINIMIZED);
  }

  private generateWeightedOptions(optionsObj: MessageOptions) {
    const optionsArray = [];
    for (const [option, weight] of Object.entries(optionsObj)) {
      for (let i = 0; i < weight; i++) {
        optionsArray.push(option);
      }
    }
    return optionsArray;
  }

  private getRandom<T>(options: T[]): T {
    return options[Math.floor(Math.random() * options.length)];
  }

  private sanitizeFirstName(s: string): string {
    if (!s || typeof s !== 'string' || s.toUpperCase() !== s) {
      return s;
    }
    s = s.trim();
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  }

  private substituteTemplateVariables(text: string, lead: Lead, agent: Agent): string {
    const isGlobalAgent = agent?.aid === 'GLOBAL';
    const replacements = {
      '{title}': this.trimWhitespace(lead.title) || '',
      '{first_name}': this.sanitizeFirstName(lead.first_name) || '',
      '{mid_name}': this.trimWhitespace(lead.mid_name) || '',
      '{last_name}': lead.last_name ? lead.last_name.trim() : '',
      '{suffix}': this.trimWhitespace(lead.suffix) || '',
      '{address1}': this.trimWhitespace(lead.address1) || '',
      '{address2}': this.trimWhitespace(lead.address2) || '',
      '{city}': this.trimWhitespace(lead.city) || '',
      '{state}': this.trimWhitespace(lead.state) || '',
      '{zip}': lead.zip || '',
      '{email}': this.trimWhitespace(lead.email) || '',
      '{lead_phone}': lead.lead_phone || '',
      '{extern_id}': lead.extern_id || '',
      '{aux_data1}': this.trimWhitespace(lead.aux_data1) || '',
      '{aux_data2}': this.trimWhitespace(lead.aux_data2) || '',
      '{aux_data3}': this.trimWhitespace(lead.aux_data3) || '',
      '{aux_data4}': this.trimWhitespace(lead.aux_data4) || '',
      '{aux_data5}': this.trimWhitespace(lead.aux_data5) || '',
      '{agent_first_name}': agent?.first_name || '',
      '{agent_last_name}': isGlobalAgent ? '' : agent?.last_name || '',
      '{agent_full_name}': isGlobalAgent ? agent?.first_name || '' : agent?.display_name || ''
    };
    const repl = new RegExp(Object.keys(replacements).join('|'), 'g');
    return text.replace(repl, matched => replacements[matched]);
  }

  private trimWhitespace(s: string): string {
    if (!s || typeof s !== 'string') {
      return s;
    }
    return s.trim();
  }
}
