import { Component, OnInit } from '@angular/core';
import { SignoutService } from '../services/signout.service';
import { SkillsCheckService } from '../services/skills-check.service';

@Component({
  selector: 'app-contact-manager-page',
  templateUrl: './contact-manager-page.component.html',
  styleUrls: ['./contact-manager-page.component.scss']
})
export class ContactManagerPageComponent implements OnInit {
  constructor(private signoutService: SignoutService, private skillsCheckService: SkillsCheckService) {}

  ngOnInit(): void {
    this.skillsCheckService.stopSkillsCheckMonitor();
  }

  signOut() {
    this.signoutService.signout();
  }
}
