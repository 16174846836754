import { Pipe, PipeTransform } from '@angular/core';

import { DatePipe } from '@angular/common';
import { LanguageService } from '../services/language.service';

@Pipe({
  name: 'date'
})
export class DateProxyPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}
  transform(value: any, pattern: string = 'mediumDate'): any {
    const currentLang = this.languageService.getCurrentLanguage();
    const ngPipe = new DatePipe(currentLang);

    if (pattern === 'monthDay') {
      if (['fr-CA', 'es-MX'].includes(currentLang)) {
        return ngPipe.transform(value, 'd MMM');
      }
      return ngPipe.transform(value, 'MMM d');
    }

    return ngPipe.transform(value, pattern);
  }
}
