import { MonoTypeOperatorFunction } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Agent } from '../models/agent';

/**
 * Filter control on when the Agent observable should emit a new value. Only works for input of type Agent.
 *
 * Only emits when the agent is different from the previous agent or when the agent is a global agent and the team_id has changed.
 *
 * @export
 * @return {*}  {MonoTypeOperatorFunction<Agent>}
 */
export function newAgentOrGlobalAgentTeamChange(): MonoTypeOperatorFunction<Agent> {
  let previousAgent: Agent = null;

  return source =>
    source.pipe(
      filter((agent: Agent) => {
        if (
          !agent ||
          !previousAgent ||
          agent.uida !== previousAgent.uida ||
          (agent.aid === 'GLOBAL' && agent.team_id !== previousAgent.team_id)
        ) {
          previousAgent = agent;
          return true;
        }

        return false;
      }),
      map(agent => {
        if (agent && agent.aid === 'GLOBAL' && !agent.team_id) {
          agent.team_id = '';
        }
        return agent;
      })
    );
}
