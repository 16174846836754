import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { CompletedEvent, EventCssClass, EventState, EventType, OptOutEvent, ReopenEvent } from '../models/event';

import { Conversation } from '../models/conversation';
import { JobDisposition } from '../models/job';
import { AuthService } from '../services/auth.service';
import { ConvsService } from '../services/convs.service';
import { JobsService } from '../services/jobs.service';

import firebase = require('firebase');

@Component({
  selector: 'app-conv-actions',
  templateUrl: './conv-actions.component.html',
  styleUrls: ['./conv-actions.component.scss']
})
export class ConvActionsComponent implements OnInit {
  @Output() event = new EventEmitter<CompletedEvent | OptOutEvent | ReopenEvent>();
  data$ = combineLatest([
    this.jobsService.currentJob$,
    this.convsService.actionableConversations$,
    this.convsService.currentConversation$.pipe(filter(conv => !!conv))
  ]).pipe(
    map(([currentJob, actionableConvs, currentConv]) => {
      const dispositions = currentJob.dispositions;
      const hasNextReply = this.hasNextReply(actionableConvs, currentConv);
      return {
        currentConv,
        hasNextReply,
        convs: actionableConvs,
        dispositions
      };
    })
  );
  subs: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private convsService: ConvsService,
    private jobsService: JobsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {}

  createNewConv() {
    this.convsService.startNewConv();
  }

  getPrevReply(currentConv: Conversation, convs: Conversation[]) {
    if (convs && convs.length > 0) {
      const currentIndex = convs.findIndex(conv => conv.id === currentConv.id);
      const nextIndex = currentIndex - 1 === -1 ? convs.length - 1 : currentIndex - 1;
      const c = convs[nextIndex];
      this.router.navigate(['../', c.id], { relativeTo: this.route });
    }
  }

  getNextReply(currentConv: Conversation, convs: Conversation[]) {
    if (convs && convs.length > 0) {
      const currentIndex = convs.findIndex(conv => conv.id === currentConv.id);
      const nextIndex = currentIndex + 1 === convs.length ? 0 : currentIndex + 1;
      const c = convs[nextIndex];
      this.router.navigate(['../', c.id], { relativeTo: this.route });
    }
  }

  onCompletedConversation(currentConv: Conversation, disposition: JobDisposition = null) {
    const s = this.authService.agent$.pipe(take(1)).subscribe(agent => {
      if (currentConv && currentConv.isCompleted) {
        console.log('Conversation reopening', { currentConv });
        const reopenEvent = {
          agent,
          date: firebase.firestore.FieldValue.serverTimestamp(),
          messageType: EventType.reopenEvent,
          text: 'Conversation re-opened',
          state: EventState.read,
          cssClass: EventCssClass.info,
          account_id: this.jobsService.getCurrentJob().account_id,
          disposition: null
        };
        this.event.emit(reopenEvent as ReopenEvent);
      } else {
        console.log('Conversation completed disposition', { disposition, currentConv });
        const completedEvent = {
          agent,
          date: firebase.firestore.FieldValue.serverTimestamp(),
          messageType: EventType.completedEvent,
          text: 'Conversation completed',
          state: EventState.completed,
          cssClass: EventCssClass.completed,
          account_id: this.jobsService.getCurrentJob().account_id,
          disposition
        };
        this.event.emit(completedEvent as CompletedEvent);
      }
    });

    this.subs.push(s);
  }

  onTriggerOptOut(currentConv: Conversation, disposition: JobDisposition = null) {
    // TODO: Need to update this to capture an opt out disposition if the job has dispositions.
    // Otherwise don't set anything or set as null.
    console.log(`Triggering opt out for lead ${currentConv.lead.lead_phone}`);
    const agent = this.authService.agent.value;
    this.event.emit({
      agent,
      cssClass: EventCssClass.info,
      date: firebase.firestore.FieldValue.serverTimestamp(),
      state: EventState.pending,
      messageType: EventType.optOutEvent,
      text: 'The contact has been opted out',
      phone: currentConv.lead.lead_phone,
      conversation_id: currentConv.id,
      account_id: this.jobsService.getCurrentJob().account_id,
      disposition
    } as OptOutEvent);
  }

  private hasNextReply(convs: Conversation[], currentConv: Conversation) {
    let hasNext = true;
    if (!convs || convs.length === 0) {
      hasNext = false;
    } else if (convs.length === 1 && (!currentConv || convs[0].id === currentConv.id)) {
      hasNext = false;
    }
    return hasNext;
  }
}
