import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Agent } from '../models/agent';
import { AlertsService } from './alerts.service';
import { AuthService } from './auth.service';
import { ConvsService } from './convs.service';
import { JobsService } from './jobs.service';
import { ResponderService } from './responder.service';
import { SkillsCheckService } from './skills-check.service';
import { StatsService } from './stats.service';
import { TestJobsService } from './test-jobs.service';
import { TransferService } from './transfer.service';

@Injectable({
  providedIn: 'root'
})
export class SignoutService {
  constructor(
    private alertsService: AlertsService,
    private authService: AuthService,
    private convsService: ConvsService,
    private jobsService: JobsService,
    private modalService: NgbModal,
    private responderService: ResponderService,
    private router: Router,
    private skillsCheckService: SkillsCheckService,
    private statsService: StatsService,
    private testJobsService: TestJobsService,
    private transferService: TransferService
  ) {
    this.authService.signoutEvent.subscribe(({ shouldSignOut, agent, signOutAuth }) => {
      if (shouldSignOut) {
        console.log('Signout event triggered');
        this.signout({ agent, signOutAuth });
      }
    });
  }

  async signout({ agent, signOutAuth }: { agent?: Agent; signOutAuth?: boolean } = { agent: null, signOutAuth: true }) {
    try {
      if (!agent) {
        agent = this.authService.getAgent();
      }

      if (agent && agent.aid) {
        await this.router.navigateByUrl(`accounts/${agent.aid}/logout`);
      }

      this.modalService.dismissAll();

      // Adding a 3s delay before signing out so the user sits on the logout screen for a few seconds.
      // The delay prevents flashing so its not so jarring when the logout screen shows.
      setTimeout(async () => {
        await this.statsService.signOut({ agent, sendSignoutStats: signOutAuth });
        this.alertsService.signOut();
        this.responderService.signOut();
        this.convsService.signOut();
        this.testJobsService.signOut();
        this.jobsService.signOut();
        this.transferService.signOut();
        this.skillsCheckService.stopSkillsCheckMonitor();
        this.authService.signOut(signOutAuth).then(() => this.router.navigateByUrl('/'));
      }, 3000);
    } catch (error) {
      console.error('Failed logging out', error);
    }
  }
}
