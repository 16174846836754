import { Component, Input, OnInit } from '@angular/core';

import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Conversation } from '../models/conversation';
import { Lead } from '../models/leads';
import { AuthService } from '../services/auth.service';
import { newAgentOrGlobalAgentTeamChange } from '../utils/globalAgent';
import { isSmallScreenHeight } from '../utils/screens';

@Component({
  selector: 'app-conv-sidebar-lead-info',
  templateUrl: './conv-sidebar-lead-info.component.html',
  styleUrls: ['./conv-sidebar-lead-info.component.scss']
})
export class ConvSidebarLeadInfoComponent implements OnInit {
  @Input() data: { lead: Lead; auxData: string[] };

  // NOTE: This conversation does not automatically update on changes to the conversation doc because the root
  // conversation observable in conv.component uses DistinctUntilKeyChanged on id.
  // If we need to show changes to the AI Score values in real time we will need to subscribe to the ai_scores
  // property as its own observable like we did for ai_suggestion.
  @Input() conversation: Conversation;

  agent$ = this.authService.agent$.pipe(
    newAgentOrGlobalAgentTeamChange(),
    map(agent => ({
      isGlobalAgent: agent?.aid === 'GLOBAL'
    }))
  );

  isExpanded = !isSmallScreenHeight();
  showAiScores = !environment.production && !environment.staging;

  constructor(private authService: AuthService) {}

  ngOnInit() {}
}
