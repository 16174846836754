import { AppModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

if (environment.production) {
  enableProdMode();
  if (window) {
    window.window.console.warn = () => {};
    console.log = () => {};
    window.console.table = () => {};
  }
}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
