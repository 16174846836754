import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { filter, map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Job } from '../models/job';
import { JobsService } from '../services/jobs.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobsGuard implements CanActivate {
  constructor(private jobsService: JobsService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.jobsService.loaded$.pipe(
      filter(Boolean),
      switchMap(() => this.jobsService.availableJobs$),
      map((jobs: Job[]) => {
        if (!jobs || jobs.length === 0) {
          console.log('No jobs detected in jobs guard, routing to pending');
          this.router.navigate(['accounts', next.params.accountId, 'pending']);
          return false;
        }
        return true;
      })
    );
  }
}
