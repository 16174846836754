import { Component, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { JobsService } from '../services/jobs.service';
import { SignoutService } from '../services/signout.service';
import { SkillsCheckService } from '../services/skills-check.service';

@Component({
  selector: 'app-pending-agent-page',
  templateUrl: './pending-agent-page.component.html',
  styleUrls: ['./pending-agent-page.component.scss']
})
export class PendingAgentPageComponent implements OnInit {
  jobs$ = this.jobsService.availableJobs$.pipe(
    tap(jobs => {
      if (jobs.length > 0) {
        this.router.navigate(['jobs', jobs[0].id ? jobs[0].id : 'default']);
      }
    })
  );

  constructor(
    private jobsService: JobsService,
    private router: Router,
    private signoutService: SignoutService,
    private skillsCheckService: SkillsCheckService
  ) {}

  ngOnInit() {
    this.skillsCheckService.startSkillsCheckMonitorForGlobalAgents();
  }

  signOut() {
    this.signoutService.signout();
  }
}
