import { Component, OnInit } from '@angular/core';

import { AuthService } from '../services/auth.service';
import { SignoutService } from '../services/signout.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  afUser$ = this.authService.afAuthUser$;

  constructor(private authService: AuthService, private signoutService: SignoutService) {}

  ngOnInit() {}

  signOut() {
    this.signoutService.signout();
  }
}
