import * as firebase from 'firebase/app';

import { AngularFirestore, DocumentChangeAction, DocumentReference, QueryFn } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, Subscription, combineLatest, from, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { Job, Template } from '../models/job';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { isEqual } from 'lodash';
import { Agent } from '../models/agent';
import { QuestionBase } from '../models/conversation';
import { newAgentOrGlobalAgentTeamChange } from '../utils/globalAgent';
import { AuthService } from './auth.service';
import { StatsService } from './stats.service';
import { TestJobsService } from './test-jobs.service';
import { TimeService } from './time.service';

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  availableJobs = new BehaviorSubject<Job[]>([]);
  availableJobs$ = this.availableJobs.asObservable();
  private currentAgent: Agent;
  currentJob = new BehaviorSubject<Job>(null);
  currentJob$ = this.currentJob.asObservable().pipe(distinctUntilChanged());
  currentJobAiEnabled$ = this.currentJob$.pipe(map(job => job?.ai_enabled));
  currentJobId = new BehaviorSubject<string>(null);
  currentJobId$ = this.currentJobId.asObservable().pipe(distinctUntilChanged());

  loaded = new BehaviorSubject<boolean>(false);
  loaded$ = this.loaded.asObservable();

  mainServiceSubs: Subscription[] = [];

  constructor(
    private afs: AngularFirestore,
    private authService: AuthService,
    private router: Router,
    private statsService: StatsService,
    private testJobsService: TestJobsService,
    private timeService: TimeService
  ) {
    // Subscription to start or end the main sub based on auth state.
    this.loaded$
      .pipe(
        distinctUntilChanged(),
        switchMap(loaded =>
          this.authService.agent$.pipe(
            newAgentOrGlobalAgentTeamChange(),
            map(agent => ({ loaded, agent }))
          )
        ),
        switchMap(({ loaded, agent }) =>
          this.testJobsService.testJobId$.pipe(
            distinctUntilChanged(),
            map(testJobId => ({ loaded, agent, testJobId }))
          )
        )
      )
      .subscribe(({ loaded, agent, testJobId }) => {
        console.log({ 'Jobs service loaded': loaded, Agent: agent, 'Test job id': testJobId });
        if (!agent) {
          this.signOut();
        } else if (testJobId) {
          if (loaded) {
            this.stopMainSubs();
          }
          this.startTestSub(testJobId, agent.aid);
        } else {
          if (loaded) {
            this.stopMainSubs();
          }
          this.startMainSub();
        }
      });
  }

  startMainSub() {
    this.startAvailableJobsSub();
    this.startCurrentJobSub();
  }

  startTestSub(testJobId: string, aid: string) {
    this.startTestJobSub(testJobId, aid);
    this.startCurrentJobSub();
  }

  private startAvailableJobsSub() {
    // NOTE: To make this work for both account_id and base_account_id we need to make
    // a second snapshotChanges subscription for account_id with the same query
    // params and combine the two snapshop results into a single observable with merge.
    const availableJobsSub = this.authService.agent$
      .pipe(
        newAgentOrGlobalAgentTeamChange(),
        filter(agent => !!agent),
        tap(agent => console.log(`Starting jobs service main sub for ${agent.uida}.`)),
        switchMap((agent: Agent) =>
          this.afs
            .collection<Job>('jobs', this.getQueryFNByAgentType(agent))
            .snapshotChanges()
            .pipe(
              // Series of job filters to remove inactive, expired, and out of date jobs.
              // Jobs cannot be filtered out via query because of Firestore query limitations.
              map(actions => this.determineJobsFromActions(actions)),
              map(jobs => this.filterTestJobs(jobs)),
              // Recheck valid job start/end dates once every min starting immediately
              switchMap(jobs => timer(0, 1000 * 60 * 1).pipe(mapTo(jobs))),
              map(jobs => this.filterJobsByStartDate(jobs)),
              map(jobs => this.filterJobsByEndDate(jobs)),
              map(jobs => this.filterJobsByCanvassersSchedule(jobs, agent)),
              map(jobs => {
                const currentAvailableJobs = this.availableJobs.value;

                if (!currentAvailableJobs.length) {
                  // If there was no currentlyAvailableJobs, then this is either the initial loading or the user is moving
                  // from the pending page to only having a single job. Either way, there is no reason to mark any jobs
                  // as "new".
                  return jobs;
                }

                // If any of the incoming jobs are not currently in the currentlyAvailableJobs array they need to be marked
                // as new. OR if the incoming job IS already in the array but is also already marked as new and that isNew
                // time is within the last 3 min, keep that job marked as new.
                jobs.forEach(job => {
                  const currentJob = currentAvailableJobs.find(caj => caj.id === job.id);
                  if (!currentJob) {
                    // set the isNew datetime for 3 min from now.
                    const threeMin = 3 * 60 * 1000;
                    job.isNew = new Date(this.timeService.now.getTime() + threeMin);
                  } else if (currentJob.isNew && currentJob.isNew > this.timeService.now) {
                    job.isNew = currentJob.isNew;
                  }
                });
                return jobs;
              })
            )
        )
      )
      .subscribe(availableJobs => {
        console.log('Available jobs after date filter: ', availableJobs);
        this.availableJobs.next(availableJobs);
        this.loaded.next(true);
      });

    this.mainServiceSubs.push(availableJobsSub);
  }

  private startCurrentJobSub() {
    const currentJobSub = combineLatest([
      this.currentJobId,
      this.availableJobs$,
      this.authService.agent$.pipe(newAgentOrGlobalAgentTeamChange())
    ])
      .pipe(filter(([jobId, jobs, agent]) => !!jobId && !!agent))
      .subscribe(
        ([jobId, jobs, agent]) => {
          if (agent && agent.aid === 'GLOBAL' && !agent.team_id) {
            // This is handled by the global agent service
            return;
          }

          if (!jobs || !jobs.length) {
            this.router.navigate(['accounts', agent.aid, 'pending']);
          } else {
            const job = jobs.find(j => j.id === jobId);
            if (job) {
              console.log('Found job, setting job now');
              this.currentJob.next(job);
            } else {
              console.log('Job not found, routing to default');
              this.router.navigate(['accounts', agent.aid, 'jobs', 'default']);
            }
          }
        },
        error => console.error('Error during job subscription sequence:', error)
      );
    this.mainServiceSubs.push(currentJobSub);
  }

  private startTestJobSub(testJobId: string, aid: string) {
    const testSub = this.authService.agent$
      .pipe(
        newAgentOrGlobalAgentTeamChange(),
        filter(agent => !!agent),
        tap(agent => console.log(`Starting test job sub for ${agent.uida}.`)),
        switchMap(() => this.afs.doc<Job>(`jobs/${testJobId}`).get()),
        map(doc => {
          const job = doc.data();
          job.id = doc.id;
          return job as Job;
        }),
        map(job => {
          // Format the test job name to match the Admin UI
          const date = new Date(job.created_date + 'Z');
          const weekday = date.toLocaleString('default', { weekday: 'long' });
          const month = date.toLocaleString('default', { month: 'long' });
          const day = date.toLocaleString('default', { day: 'numeric' });
          const time = date.toLocaleString('default', { hour: 'numeric', minute: 'numeric', second: 'numeric' });
          job.name = `(Test) ${weekday}, ${month} ${day}, ${time}`;
          return job;
        })
      )
      .subscribe(job => {
        console.log('Test job: ', job);
        this.availableJobs.next([job]);
        this.loaded.next(true);
        this.router.navigate(['accounts', aid, 'jobs', job.id]);
      });

    this.mainServiceSubs.push(testSub);
  }

  stopMainSubs() {
    console.log('Stopping main subs');
    this.mainServiceSubs.forEach(s => s.unsubscribe());
    this.mainServiceSubs = [];
  }

  getQueryFNByAgentType(agent: Agent): QueryFn {
    if (agent.aid === 'GLOBAL') {
      return ref =>
        ref
          .where('has_canvassers_scheduled', '==', true)
          .where('status', '==', 'active')
          .where('canvassers_schedule.approved', '==', true)
          .where('canvasser_team_ids', 'array-contains', agent.team_id)
          .orderBy('canvassers_schedule.start_time', 'asc');
    }

    return ref =>
      ref
        .where('base_account_id', '==', agent.aid)
        .where('agent_ids', 'array-contains', agent.uida)
        .where('status', '==', 'active')
        .orderBy('created_date', 'asc');
  }

  private determineJobsFromActions(actions: DocumentChangeAction<Job>[]): Job[] {
    const jobs: Job[] = [];
    for (const action of actions) {
      if (action.payload.type === 'removed') {
        continue;
      }
      const job = action.payload.doc.data() as Job;
      job.id = action.payload.doc.id;
      jobs.push(job);
    }
    return jobs;
  }

  private filterJobsByStartDate(jobs): Job[] {
    return jobs.filter(job => {
      const start_date = new Date(`${job.start_date}T00:00:00`);
      return start_date <= this.timeService.now;
    });
  }

  private filterJobsByEndDate(jobs): Job[] {
    return jobs.filter(job => {
      const end_date = new Date(`${job.end_date}T23:59:59`);
      return end_date >= this.timeService.now;
    });
  }

  private filterJobsByCanvassersSchedule(jobs, agent): Job[] {
    if (agent.aid !== 'GLOBAL') {
      return jobs;
    }

    return jobs.filter(job => {
      console.log(job.canvassers_schedule);
      return (
        job.canvassers_schedule?.start_time.toDate() <= this.timeService.now &&
        job.canvassers_schedule?.end_time.toDate() >= this.timeService.now
      );
    });
  }

  private filterTestJobs(jobs: Job[]): Job[] {
    return jobs.filter(job => {
      return job.job_type !== 'P2P-TEST';
    });
  }

  signOut() {
    this.stopMainSubs();
    this.currentJob.next(null);
    this.availableJobs.next([]);
    if (this.loaded.value) {
      this.loaded.next(false);
    }
    console.log('Jobs service signed out');
  }

  clearNewJobs(): void {
    if (!this.availableJobs.value.find(j => j.isNew)) {
      return;
    }

    const newJobs = this.availableJobs.value.map(j => {
      delete j.isNew;
      return j;
    });
    this.availableJobs.next(newJobs);
  }

  getCurrentJob(): Job {
    return this.currentJob.value;
  }

  getDefaultJob(): Job {
    const jobs = this.availableJobs.value;

    if (!jobs || jobs.length === 0) {
      console.log('No default jobs, returning null');
      return null;
    }

    return jobs[jobs.length - 1];
  }

  setCurrentJob(jobId: string) {
    console.log('setting current job', jobId);
    this.statsService.setCurrentJobId(jobId);

    const prevJobId = this.currentJobId.value;
    if (jobId !== prevJobId && !!prevJobId) {
      this.statsService.performedAction('changed current job', {
        previous_job_id: prevJobId,
        current_job_id: jobId
      });
    }

    this.currentJobId.next(jobId);
  }

  updateJobQuestion(job: DocumentReference, original: QuestionBase<any>, updated: QuestionBase<any>): Observable<void> {
    const payload = {};
    const id = original.id;
    const cType = original.controlType;
    const oResp = original.response;
    const uResp = updated.response;
    const key = `question_responses.${id}`;

    if (cType === 'select') {
      const oKey = `${key}.${oResp}`;
      const uKey = `${key}.${uResp}`;

      // increment updated response value if updated response is a valid
      // value and changed
      if (![oResp, undefined, null, ''].includes(uResp)) {
        payload[uKey] = firebase.firestore.FieldValue.increment(1);
      }

      // decrement original response value if original response is a valid
      // value and changed
      if (![uResp, undefined, null, ''].includes(oResp)) {
        payload[oKey] = firebase.firestore.FieldValue.increment(-1);
      }
    } else if (cType === 'number') {
      const amount: number = oResp ? uResp - oResp : uResp;
      payload[key] = firebase.firestore.FieldValue.increment(amount);
    } else if (cType === 'text') {
      if (![undefined, null, '', oResp].includes(uResp)) {
        payload[key] = firebase.firestore.FieldValue.increment(1);
      }
    }

    console.log('Job update payload', payload);
    return this.updateJobQuestions(job, payload);
  }

  updateJobQuestions(
    job: DocumentReference,
    responsesUpdatePayload: Record<string, Record<string, number> | Record<string, string> | number>
  ): Observable<void> {
    return from(job.update(responsesUpdatePayload));
  }

  getTemplateById(id: string): Template {
    if (this.currentJob.value.templates.length === 0) {
      console.log('template: currentjob has no templates');
      return null;
    }

    const temps = this.currentJob.value.templates.filter(t => t.id === id);
    if (temps.length === 0) {
      console.log('template: didnt find tmpl with id', id);
      return null;
    }

    console.log('template: got', temps[0]);
    return temps[0];
  }

  isSameJob(firstJob: Job, secondJob: Job): boolean {
    const oldData = { ...firstJob };
    const newData = { ...secondJob };
    for (const key of [
      'Delivery_Receipt_ErrCodes',
      'Delivery_Receipt_Statuses',
      'accountRef',
      'daily_cost',
      'daily_mms_rx',
      'daily_mms_tx',
      'daily_sms_rx',
      'daily_sms_tx',
      'total_cost',
      'total_mms_rx',
      'total_mms_tx',
      'total_sms_rx',
      'total_sms_tx',
      'last_touched_date',
      'leads_remaining'
    ]) {
      delete oldData[key];
      delete newData[key];
    }

    return isEqual(oldData, newData);
  }
}
