import { Injectable } from '@angular/core';
import { DomainService } from './domain.service';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { JobsService } from './jobs.service';

@Injectable({
  providedIn: 'root'
})
export class ReadyIntervalService {
  private readyInterval: BehaviorSubject<number> = new BehaviorSubject(300);
  readyInterval$ = this.readyInterval.asObservable();

  constructor(private domainService: DomainService, private jobsService: JobsService) {
    this.subscribeToReadyIntervals();
  }

  getReadyInterval() {
    return this.readyInterval.value;
  }

  subscribeToReadyIntervals() {
    this.domainService.systemConfigs$
      .pipe(
        map(config => config.ready_interval),
        switchMap(configInterval =>
          this.jobsService.currentJob$.pipe(
            map(job => job?.ready_interval),
            distinctUntilChanged(),
            map(jobReadyInterval => {
              if (!jobReadyInterval) {
                return configInterval;
              }
              return Math.max(configInterval, jobReadyInterval);
            })
          )
        )
      )
      .subscribe(interval => this.readyInterval.next(interval));
  }
}
