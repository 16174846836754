import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { DomainService } from '../services/domain.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  domain$ = this.domainService.domain$;
  error = false;
  form: FormGroup;
  submitting = false;
  version: string = environment.version;
  isShowingLogIn = false;
  isShownLogOut = false;
  activeTab = 'inboxtab';

  constructor(private auth: AuthService, private domainService: DomainService, private fb: FormBuilder) {
    this.form = this.fb.group({
      email: [null, Validators.required],
      password: [null, Validators.required]
    });
  }

  ngOnInit() {}

  onSubmit() {
    console.table(this.form.value);
    this.submitting = true;
    const { email, password } = this.form.value;
    try {
      this.auth
        .loginWithEmailAndPassword(email, password, this.domainService.domain.value.aid)
        .catch(error => {
          console.error(error);
          this.error = true;
        })
        .finally(() => (this.submitting = false));
    } catch (e) {
      console.error(e);
      this.error = true;
      this.submitting = false;
    }
  }
}
