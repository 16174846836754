// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  afConfig: {
    apiKey: 'AIzaSyBoFpqv7CFSAVpHpTzVKjhR1UFQel3ZHzE',
    authDomain: 'pwademo-40086.firebaseapp.com',
    databaseURL: 'https://pwademo-40086.firebaseio.com',
    assetStorageURL:
      'https://firebasestorage.googleapis.com/v0/b/pwademo-40086.appspot.com/o/app_assets%2F',
    projectId: 'pwademo-40086',
    storageBucket: 'pwademo-40086.appspot.com',
    messagingSenderId: '1087498387274',
    appId: '1:1087498387274:web:eeb14eb48f013f0d'
    // apiKey: 'AIzaSyAHYpMFClO3X3tx-IyM8Y7LpB2IOE7zufk',
    // authDomain: 'platform-199617-b4f58.firebaseapp.com',
    // databaseURL: 'https://platform-199617-b4f58.firebaseio.com',
    // assetStorageURL:
    //   'https://firebasestorage.googleapis.com/v0/b/platform-199617.appspot.com/o/app_assets%2F',
    // projectId: 'platform-199617',
    // storageBucket: 'platform-199617.appspot.com',
    // messagingSenderId: '78404847957',
    // appId: '1:78404847957:web:f581de09c35d77122fc7dc',
    // measurementId: 'G-3S036WQ2VY'
  },
  production: false,
  staging: false,
  version: require('../../package.json').version
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
