import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TeamGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.agent$.pipe(
      map(agent => {
        // This should never happen since the auth guard should catch this first
        if (!agent) {
          return false;
        }

        if (agent.aid === 'GLOBAL' && !agent.team_id) {
          console.log('No team detected in team guard, routing to awaiting-team');
          return this.router.createUrlTree(['accounts', next.params.accountId, 'awaiting-team']);
        }

        return true;
      })
    );
  }
}
