import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionLogoutComponent } from '../session-logout/session-logout.component';

@Injectable()
export class CanDeactivateSessionLogout implements CanDeactivate<SessionLogoutComponent> {
  constructor() {}

  canDeactivate(
    component: SessionLogoutComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return nextState.url.endsWith('login');
  }
}
