import { Component, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-skills-check-modal',
  templateUrl: './skills-check-modal.component.html',
  styleUrls: ['./skills-check-modal.component.scss']
})
export class SkillsCheckModalComponent implements OnInit {
  number1: number;
  number2: number;
  answer: number;
  options: number[];
  timeout: NodeJS.Timeout;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.makeMathProblem();

    this.timeout = setTimeout(() => {
      this.activeModal.dismiss();
    }, 60 * 1000);
  }

  onSubmit(guess: number) {
    if (guess === this.answer) {
      clearTimeout(this.timeout);
      this.activeModal.close();
    } else {
      this.activeModal.dismiss();
    }
  }

  private makeMathProblem() {
    this.number1 = Math.floor(Math.random() * 9) + 1;
    this.number2 = Math.floor(Math.random() * 5) + 1;

    this.answer = this.number1 * this.number2;

    // Suffle the options array so the answer isn't always the same button.
    this.options = this.shuffleArray([
      this.answer,
      this.answer - (Math.floor(Math.random() * 10) + 1),
      this.answer + (Math.floor(Math.random() * 10) + 1)
    ]);
  }

  shuffleArray(array) {
    let currentIndex = array.length;
    let randomIndex = 0;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
  }
}
