import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { audit, map, switchMap, tap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Agent } from '../models/agent';
import { Conversation } from '../models/conversation';
import { EventCssClass } from '../models/event';
import { AnnouncementsService } from '../services/announcements.service';
import { AuthService } from '../services/auth.service';
import { ConvsService } from '../services/convs.service';
import { DomainService } from '../services/domain.service';
import { JobsService } from '../services/jobs.service';
import { NotificationsService } from '../services/notifications.service';
import { RedactedService } from '../services/redacted.service';
import { SidebarService } from '../services/sidebar.service';
import { TestJobsService } from '../services/test-jobs.service';
import { mapLastEventTextSystemMessages } from '../utils/events';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() agent: Agent;

  @Output() newConvo = new EventEmitter<void>();

  activeConvs$: Observable<Conversation[]> = this.convsService.actionableConversations$.pipe(
    audit(convs => this.notificationsService.auditInterval$),
    tap(convs => this.checkForEmptyList(convs, 'active')),
    mapLastEventTextSystemMessages
  );
  allConvs$: Observable<Conversation[]> = this.convsService.subscribeToAllConversations.pipe(
    switchMap(() => this.convsService.allConversations$),
    audit(convs => this.notificationsService.auditInterval$),
    tap(convs => this.checkForEmptyList(convs, 'all')),
    mapLastEventTextSystemMessages
  );
  errorConvs$: Observable<Conversation[]> = this.convsService.subscribeToErrorConversations.pipe(
    switchMap(() => this.convsService.errorConversations$),
    audit(convs => this.notificationsService.auditInterval$),
    tap(convs => this.checkForEmptyList(convs, 'error')),
    mapLastEventTextSystemMessages
  );
  pendingConvs$: Observable<Conversation[]> = this.convsService.pendingConversations$.pipe(
    audit(convs => this.notificationsService.auditInterval$),
    tap(convs => this.checkForEmptyList(convs, 'pending')),
    mapLastEventTextSystemMessages
  );
  stopConvs$: Observable<Conversation[]> = this.convsService.subscribeToStopConversations.pipe(
    switchMap(() => this.convsService.stopConversations$),
    audit(convs => this.notificationsService.auditInterval$),
    tap(convs => this.checkForEmptyList(convs, 'stop')),
    mapLastEventTextSystemMessages
  );
  likelyStop$: Observable<Conversation[]> = this.convsService.subscribeToLikelyStopConversations.pipe(
    switchMap(() => this.convsService.likelyStopConversations$),
    audit(convs => this.notificationsService.auditInterval$),
    tap(convs => this.checkForEmptyList(convs, 'likelyStop')),
    mapLastEventTextSystemMessages
  );

  hasAnnouncements$ = this.announcementService.currentJobHasUnreadAnnouncements$;

  domain$ = this.domainService.domain$;

  jobSwitcherData$ = combineLatest([this.jobsService.availableJobs$, this.jobsService.currentJob$]).pipe(
    map(([availableJobs, currentJob]) => {
      return {
        isGlobalAgent: this.authService.isGlobalAgent(),
        availableJobs,
        newJobCount: availableJobs.filter(j => j.isNew).length,
        currentJob
      };
    })
  );

  activeTab = 'inboxtab';
  hideDrafts$ = this.convsService.filterDraftMessages.asObservable();
  hideErrors$ = this.convsService.filterErrorMessages.asObservable();
  isEmptyActiveList = false;
  isEmptyAllList = false;
  isEmptyErrorList = false;
  isEmptyPendingList = false;
  isEmptyStopList = false;
  isEmptyLikelyStopList = false;
  moreIsShowing = false;
  footerShowing = false;
  inboxTabIsHovered = false;

  constructor(
    public convsService: ConvsService,
    public jobsService: JobsService,
    public testJobsService: TestJobsService,
    public redactedService: RedactedService,
    private announcementService: AnnouncementsService,
    private authService: AuthService,
    private domainService: DomainService,
    private notificationsService: NotificationsService,
    private router: Router,
    private sidebarService: SidebarService
  ) {}

  ngOnInit() {}

  createNewConv() {
    this.convsService.startNewConv();
    this.sidebarService.closeSidebar();
  }

  goToAnnouncements() {
    const currentJob = this.jobsService.getCurrentJob();
    this.router
      .navigate(['accounts', this.authService.agent.value.aid, 'jobs', currentJob.id])
      .then(() => this.sidebarService.closeSidebar());
  }

  goToJob(jobId: string) {
    const currentJob = this.jobsService.getCurrentJob();

    if (jobId !== currentJob.id) {
      this.jobsService.setCurrentJob(jobId);
    }

    this.router.navigate(['accounts', this.authService.agent.value.aid, 'jobs', jobId]);
  }

  onNavChange(event: NgbNavChangeEvent) {
    this.convsService.setCurrentFolder(event.nextId);
  }

  onJobSwitcherOpen() {
    setTimeout(() => {
      this.jobsService.clearNewJobs();
    }, 15 * 1000);
  }

  onScroll(listType: 'actionable' | 'all' | 'stop' | 'error' | 'likelyStop') {
    if (listType === 'all' && this.convsService.finishedAllDocs) {
      this.loadMoreAllConversations();
    } else if (listType === 'actionable' && this.convsService.finishedActionableDocs) {
      this.loadMoreActionableConversations();
    } else if (listType === 'stop' && this.convsService.finishedStopDocs) {
      this.loadMoreStopConversations();
    } else if (listType === 'error' && this.convsService.finishedErrorDocs) {
      this.loadMoreErrorConversations();
    } else if (listType === 'likelyStop' && this.convsService.finishedLikelyStopDocs) {
      this.loadMoreLikelyStopConversation();
    }
  }

  startNewConversation() {
    this.convsService.startNewConv('new');
  }

  toggleHideDrafts() {
    this.convsService.toggleFilterDraftMessages();
  }

  toggleHideErrors() {
    this.convsService.toggleFilterErrorMessages();
  }

  private checkForEmptyList(convs, list) {
    let show = false;
    if (!convs || convs.length === 0) {
      show = true;
    }

    if (list === 'active') {
      this.isEmptyActiveList = show;
    } else if (list === 'all') {
      this.isEmptyAllList = show;
    } else if (list === 'stop') {
      this.isEmptyStopList = show;
    } else if (list === 'error') {
      this.isEmptyErrorList = show;
    } else if (list === 'pending') {
      this.isEmptyPendingList = show;
    } else if (list === 'likelyStop') {
      this.isEmptyLikelyStopList = show;
    }
  }

  private loadMoreActionableConversations() {
    this.convsService.fetchMoreActionableConversations();
  }

  private loadMoreAllConversations() {
    this.convsService.fetchMoreAllConversations();
  }

  private loadMoreErrorConversations() {
    this.convsService.fetchMoreErrorConversations();
  }

  private loadMoreStopConversations() {
    this.convsService.fetchMoreStopConversations();
  }

  private loadMoreLikelyStopConversation() {
    this.convsService.fetchMoreLikelyStopConversations();
  }

  // Wrote custom sorting fuctions so in the future we can add different sorting functions for different orderings
  // This could be hot swapped in the observable stream above to sort on different criteria
  private orderByLastEventDateAsc(convs$: Observable<Conversation[]>): Observable<Conversation[]> {
    return convs$.pipe(
      map(convs => {
        if (convs == null) {
          return [];
        }

        return convs.sort((a, b) => {
          if ([EventCssClass.new, EventCssClass.rehash].includes(a.state)) {
            return -1;
          } else if ([EventCssClass.new, EventCssClass.rehash].includes(b.state)) {
            return 1;
          }

          if (a.lastEventDate > b.lastEventDate) {
            return 1;
          } else if (a.lastEventDate < b.lastEventDate) {
            return -1;
          }
          return 0;
        });
      })
    );
  }

  private orderByLastEventDateDesc(convs$: Observable<Conversation[]>): Observable<Conversation[]> {
    return convs$.pipe(
      map(convs => {
        if (convs == null) {
          return [];
        }

        return convs.sort((a, b) => {
          if (a.lastEventDate > b.lastEventDate) {
            return -1;
          } else if (a.lastEventDate < b.lastEventDate) {
            return 1;
          }
          return 0;
        });
      })
    );
  }
}
