import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription, combineLatest, from, of } from 'rxjs';
import { catchError, distinctUntilKeyChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import {
  CompletedEvent,
  DeferredEvent,
  Event,
  EventCssClass,
  EventType,
  MMSEvent,
  OptOutEvent,
  QuestionResponseEvent,
  ReopenEvent,
  TextEvent,
  TransferEvent
} from '../models/event';
import { parseQuestionResponseEventText, parseTransferEventText } from '../utils/events';

import { ActivatedRoute } from '@angular/router';
import { Timestamp } from '@firebase/firestore-types';
import { TranslateService } from '@ngx-translate/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { orderBy } from 'lodash';
import { ConvActionsFooterComponent } from '../conv-actions-footer/conv-actions-footer.component';
import { ConvBodyComponent } from '../conv-body/conv-body.component';
import { AiSuggestion } from '../models/ai';
import { Conversation } from '../models/conversation';
import { Template } from '../models/job';
import { Lead } from '../models/leads';
import { DateProxyPipe } from '../pipes/date-proxy.pipe';
import { AlertsService } from '../services/alerts.service';
import { ConvsService } from '../services/convs.service';
import { EventMenuService } from '../services/event-menu.service';
import { JobsService } from '../services/jobs.service';
import { LeadsService } from '../services/leads.service';
import { RedactedService } from '../services/redacted.service';
import { SidebarService } from '../services/sidebar.service';
import { SpinnerService } from '../services/spinner.service';
import { isMediumScreenWidth } from '../utils/screens';

// Putting this interface here because it is only used by this one component
interface ConversationData {
  conv: Conversation;
  events: (Event | TextEvent | TransferEvent)[];
  isNewConversation: boolean;
  confirm: {
    title?: string;
    text?: string;
    params?: {
      time?: string;
      contact?: string;
    };
  };
  sendButtonStatus: string;
}

@Component({
  selector: 'app-conv',
  templateUrl: './conv.component.html',
  styleUrls: ['./conv.component.scss']
})
export class ConvComponent implements OnDestroy, OnInit {
  @ViewChild('actionsFooter', { static: false })
  actionsFooter: ConvActionsFooterComponent;
  @ViewChild('convBody', { static: false })
  convBody: ConvBodyComponent;
  @ViewChild('confirmSendSwal', { static: false })
  swal: SwalComponent;

  didConfirmOutsideHoursContact = false;
  isNewConversation: boolean;
  isOutsideHours: string;
  sendButtonStatus: string;
  showDetails = false;

  aiSuggestion$: Observable<AiSuggestion> = this.convsService.currentConversation$.pipe(
    filter(Boolean),
    map((conv: Conversation) => (conv.ai_suggestion as AiSuggestion) || null)
  );

  lead$: Observable<Lead> = this.convsService.currentConversation$.pipe(
    filter(Boolean),
    map((conv: Conversation) => {
      if (this.redactedService.shouldRedact) {
        return { lead_phone: conv.lead.lead_phone.slice(0, -4) + 'XXXX' } as Lead;
      }
      return conv.lead;
    })
  );

  leadTabData$ = this.lead$.pipe(
    map(lead => {
      const auxData = this.getAuxData(lead);
      return { lead, auxData };
    })
  );

  conv$ = this.route.params.pipe(
    map(params => params.convid),
    switchMap(convId => this.convsService.getCurrentConversation$(convId)),
    filter(conv => !!conv),
    distinctUntilKeyChanged('id'),
    tap(() => {
      if (this.sidebarService.initialLoad) {
        this.sidebarService.closeSidebar();
      }
      this.didConfirmOutsideHoursContact = false;
    })
  );

  events$: Observable<(Event | TextEvent | TransferEvent)[]> = this.conv$.pipe(
    switchMap(conv => {
      if (!conv?.id) {
        return of([]);
      }
      return this.convsService.getConvEvents(conv.id).pipe(
        map((events: Event[]) => orderBy(events, [this.orderEventsByDateAsc])),
        map((events: Event[]) =>
          events.map(event => {
            if (event.messageType === EventType.questionResponseEvent) {
              event.translationParams = parseQuestionResponseEventText(event.text);
              event.text = 'convBody.question';
            } else if (event.messageType === EventType.transferEvent) {
              event.translationParams = parseTransferEventText(event.text);
              event.text = 'convBody.transfer';
            }
            return event;
          })
        )
      );
    }),
    catchError((err, caught) => {
      console.error(err);
      throw err;
    })
  );

  data$ = combineLatest([
    this.aiSuggestion$,
    this.conv$,
    this.events$,
    this.convsService.actionableConversations$
  ]).pipe(
    map(([aiSuggestion, conv, events, actionableConvs]) => {
      const hasActionableConvs = this.hasActionableConvs(actionableConvs, conv.id);

      const eventsAfterLastReset = this.getEventsAfterLastReset(events);
      const hasLeadResponse = !!eventsAfterLastReset.find(e => e.cssClass === EventCssClass.lead);

      return {
        aiSuggestion,
        conv,
        events,
        isNewConversation: events.length === 0 ? true : false,
        confirm: {},
        sendButtonStatus: hasLeadResponse && hasActionableConvs ? 'next' : 'new',
        hasLeadResponse
      };
    }),
    tap(data => {
      this.isOutsideHours = data.conv?.lead?.isOutsideHours ?? null;
      this.isNewConversation = data.isNewConversation;
      this.sendButtonStatus = data.sendButtonStatus;
    }),
    map(data => this.configureOutsideHoursPrompt(data))
  );

  eventMenu$ = this.eventMenuService.currentMenu$;

  subs: Subscription[] = [];

  get isMediumScreenWidth() {
    return isMediumScreenWidth();
  }

  constructor(
    private alertService: AlertsService,
    private convsService: ConvsService,
    private jobsService: JobsService,
    private datePipe: DateProxyPipe,
    private eventMenuService: EventMenuService,
    private leadsService: LeadsService,
    private route: ActivatedRoute,
    private spinnerService: SpinnerService,
    private sidebarService: SidebarService,
    private translate: TranslateService,
    public redactedService: RedactedService
  ) {}

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  ngOnInit() {
    this.subscribeToEventMenuTemplateSelection();
  }

  createNewConv() {
    this.convsService.startNewConv(this.sendButtonStatus);
  }

  onBack() {
    if (this.showDetails) {
      this.hideDetails();
    } else {
      this.sidebarService.openSidebar();
    }
  }

  onShowDetails() {
    this.showDetails = !this.showDetails;
  }

  onEvent(
    event: CompletedEvent | MMSEvent | TextEvent | TransferEvent | OptOutEvent | QuestionResponseEvent | ReopenEvent
  ) {
    if (event.messageType === EventType.completedEvent || event.messageType === EventType.reopenEvent) {
      this.processCompletedOrReopenedEvent(event);
      this.hideDetails();
    } else if (event.messageType === EventType.textEvent || event.messageType === EventType.mmsEvent) {
      this.handleTextEvent(event);
    } else if (event.messageType === EventType.optOutEvent) {
      this.processOptOutEvent(event);
      this.hideDetails();
    }
  }

  onTemplateSelection(t: Template) {
    this.actionsFooter.onSelectedTemplate(t);
    if (this.isMediumScreenWidth) {
      this.hideDetails();
    }
  }

  private configureOutsideHoursPrompt(data: ConversationData): ConversationData {
    if (!data.conv?.lead?.isOutsideHours || data.isNewConversation) {
      // Either not after hours or conv will auto get deferred
      return data;
    }

    const isBeforeHours = data.conv.lead.isOutsideHours === 'before';
    const t = new Date(data.conv.lead.localtime);
    if (isBeforeHours) {
      t.setHours(t.getHours() + 1);
    }
    const hrStr = this.datePipe.transform(t, 'shortTime');

    data.confirm = {
      title: isBeforeHours ? 'conv.confirmBeforeHoursTitle' : 'conv.confirmAfterHoursTitle',
      text: 'conv.confirmOutsideHoursText',
      params: {
        time: hrStr,
        contact: data.conv.lead.first_name || data.conv.lead.lead_phone
      }
    };

    return data;
  }

  private deferConversation(event: TextEvent | MMSEvent) {
    const d = (event as unknown) as DeferredEvent;
    d.messageType = EventType.deferredEvent;
    d.cssClass = EventCssClass.completed;
    d.text = 'Conversation deferred due to contact timezone.';

    this.convsService
      .processConvEvent(d, this.convsService.currentConversation.value.id)
      .then(() => {
        this.reset({ resetMessageInputForm: false });
      })
      .catch(error => {
        console.error('Failed transmitting conversation: ', error);
        this.alertService.setMessage('alert.failedTransmit', { error });
      });

    if (d.thenStartNew) {
      this.createNewConv();
    }
  }

  private handleCancelledTextEvent(event: TextEvent | MMSEvent) {
    this.actionsFooter.resetMessageInputForm(event.text);
    this.convsService.stopSending();
  }

  private handleTextEvent(event: TextEvent | MMSEvent) {
    this.convsService.startSending();

    if (this.isOutsideHours) {
      if (this.isNewConversation) {
        console.log('Defer conversation because after hours');
        return this.deferConversation(event);
      }
      if (!this.didConfirmOutsideHoursContact) {
        return this.swal.fire().then(response => {
          if (response.isConfirmed) {
            this.didConfirmOutsideHoursContact = true;
            return this.processTextEvent(event);
          }
          return this.handleCancelledTextEvent(event);
        });
      }
    }

    return this.processTextEvent(event);
  }

  private hasActionableConvs(actionableConvs: Conversation[], currentConvId: string): boolean {
    let hasActionables = false;
    if (actionableConvs.length > 1) {
      hasActionables = true;
    } else if (actionableConvs.length === 1 && actionableConvs[0].id !== currentConvId) {
      hasActionables = true;
    }
    return hasActionables;
  }

  private orderEventsByDateAsc(event: Event | TextEvent | TransferEvent) {
    return event.date ? (event.date as Timestamp).toDate() : false;
  }

  private processCompletedOrReopenedEvent(event: CompletedEvent | ReopenEvent) {
    this.spinnerService.startSpinner('spinner.processingEvent');
    this.convsService.startSending();
    this.convsService
      .processConvEvent(event, this.convsService.currentConversation.value.id)
      .then(() => {
        this.reset();
      })
      .catch(error => {
        console.error(`Failed marking conversation as ${event.messageType}: `, error);
        this.alertService.setMessage('alert.failedConversationComplete', {
          error
        });
      });
  }

  private processOptOutEvent(event: OptOutEvent) {
    this.spinnerService.startSpinner('spinner.optingOutContact');
    const s3 = combineLatest([this.conv$, this.jobsService.currentJob$])
      .pipe(
        switchMap(([conv, job]) => this.leadsService.optOutLead(event, conv, job)),
        switchMap((optOutEvent: OptOutEvent) =>
          from(this.convsService.processConvEvent(optOutEvent, this.convsService.currentConversation.value.id))
        ),
        switchMap(() => this.translate.get('conv.optOutText')),
        take(1)
      )
      .subscribe((text: string) => {
        this.reset();
      });
    this.subs.push(s3);
  }

  private processTextEvent(event: TextEvent | MMSEvent) {
    this.convsService
      .processConvEvent(event, this.convsService.currentConversation.value.id)
      .then(() => {
        this.reset({ resetMessageInputForm: false });
      })
      .catch(error => {
        console.error('Failed transmitting conversation: ', error);
        this.alertService.setMessage('alert.failedTransmit', { error });
      });

    if (event.thenStartNew) {
      this.createNewConv();
    }
  }

  private subscribeToEventMenuTemplateSelection() {
    this.subs.push(
      this.eventMenuService.templateSelected$.subscribe(t => {
        if (t) {
          this.onTemplateSelection(t);
        }
      })
    );
  }

  private getAuxData(lead: Lead): string[] {
    const auxData = [];
    if (lead.extern_id) {
      auxData.push(lead.extern_id);
    }
    if (lead.aux_data1) {
      auxData.push(lead.aux_data1);
    }
    if (lead.aux_data2) {
      auxData.push(lead.aux_data2);
    }
    if (lead.aux_data3) {
      auxData.push(lead.aux_data3);
    }
    if (lead.aux_data4) {
      auxData.push(lead.aux_data4);
    }
    if (lead.aux_data5) {
      auxData.push(lead.aux_data5);
    }
    return auxData;
  }

  private hideDetails() {
    this.showDetails = false;
  }

  private getEventsAfterLastReset(events: Event[]): Event[] {
    if (!events) {
      return [];
    }

    let newEvents: Event[] = [];

    const sortedEvents = events.sort((a, b) => {
      if (a.date > b.date) {
        return 1;
      } else if (a.date < b.date) {
        return -1;
      }
      return 0;
    });

    for (const evt of sortedEvents) {
      if (evt.messageType === EventType.rehashEvent) {
        newEvents = [];
        continue;
      }

      newEvents.push(evt);
    }

    return newEvents;
  }

  private reset(
    options: { resetMessageInputForm?: boolean; resetConvBody?: boolean; stopSending?: boolean } = {
      resetMessageInputForm: true,
      resetConvBody: true,
      stopSending: true
    }
  ) {
    if (options.resetMessageInputForm) {
      this.actionsFooter.resetMessageInputForm();
    }

    if (this.convBody && options.resetConvBody) {
      this.convBody.reset();
    }

    if (options.stopSending) {
      this.convsService.stopSending();
    }

    if (this.spinnerService.isSpinning) {
      this.spinnerService.stopSpinner();
    }
  }
}
