import { Observable, interval } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ConvsService } from './convs.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  auditInterval$: Observable<number> = this.convsService.isFastModeEnabled.pipe(
    switchMap(isFastModeEnabled => (isFastModeEnabled ? interval(2000) : interval(1000)))
  );

  activeConvs$: Observable<string> = this.convsService.actionableConversations$.pipe(
    map(convs => {
      if (convs && convs.length > 0) {
        return convs.length < this.convsService.CONVERSATION_LIST_BATCH_SIZE
          ? convs.length.toString()
          : `${convs.length.toString()}+`;
      }
      return null;
    })
  );

  stopsConvs$: Observable<string> = this.convsService.stopConversations$.pipe(
    map(convs => {
      if (convs && convs.length > 0) {
        return convs.length < this.convsService.CONVERSATION_LIST_BATCH_SIZE
          ? convs.length.toString()
          : `${convs.length.toString()}+`;
      }
      return null;
    })
  );

  constructor(private convsService: ConvsService) {}
}
