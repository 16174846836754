import { Component, OnDestroy, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { SignoutService } from '../services/signout.service';
import { SkillsCheckService } from '../services/skills-check.service';

@Component({
  selector: 'app-no-team-agent-page',
  templateUrl: './no-team-agent-page.component.html',
  styleUrls: ['./no-team-agent-page.component.scss']
})
export class NoTeamAgentPageComponent implements OnInit, OnDestroy {
  supplementalCanvasser = this.authService.agent$.pipe(map(agent => !!agent.primary_canvasser_id));
  private agentTimeout: NodeJS.Timeout;
  private AGENT_TIMEOUT_MS = environment.production ? 1000 * 60 * 60 : 1000 * 60 * 2; // 1 hour or 2 minutes

  constructor(
    private authService: AuthService,
    private signoutService: SignoutService,
    private skillsCheckService: SkillsCheckService
  ) {}

  ngOnInit(): void {
    this.skillsCheckService.startSkillsCheckMonitorForGlobalAgents();
    this.agentTimeout = setTimeout(() => {
      this.signOut();
    }, this.AGENT_TIMEOUT_MS);
  }

  ngOnDestroy(): void {
    clearTimeout(this.agentTimeout);
  }

  signOut() {
    clearTimeout(this.agentTimeout);
    this.signoutService.signout();
  }
}
