import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class KeyClickService {
  private pressedKeys = new BehaviorSubject<{ [key: string]: boolean }>({});
  /**
   * A set to keep track of key combinations for which the default behavior should be prevented.
   *
   * Combinations are added as a comma separated string. (e.g. 'Control,Enter')
   */
  private preventDefaultKeyCombos = new Set<string>();
  keyDown$ = fromEvent(this.document, 'keydown');
  keyUp$ = fromEvent(this.document, 'keyup');

  /**
   * Observable that emits 'true' when a shortcut key combination is pressed.
   * This observable will repeat if the key combination is held down since the key repeat interval will continue to emit.
   *
   * This observable checks for the following key combinations:
   * - Control + Enter
   * - Meta (Command on Mac) + Enter
   * - Control + Space
   */
  shortcutPressed$ = this.pressedKeys.pipe(
    map(keys => this.hasShortcut(keys)),
    filter(hasShortcut => hasShortcut)
  );

  /**
   * Observable that emits 'true' when only the Enter key is pressed.
   * This observable filters out any other key presses and only emits when Enter is the only key pressed.
   */
  enterOnlyPressed$ = this.pressedKeys.pipe(
    map(keys => this.isOnlyKey('Enter')),
    filter(enterPressed => enterPressed)
  );

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.subscribeToKeyClick();
  }

  hasPressedKey(key: string): boolean {
    return !!this.pressedKeys.getValue()[key];
  }

  hasShortcutHeld(): boolean {
    return this.hasShortcut(this.pressedKeys.getValue());
  }

  preventDefaultOn(keys: string) {
    const sortedKeys = keys
      .split(',')
      .map(key => key.trim())
      .sort()
      .join(',');
    this.preventDefaultKeyCombos.add(sortedKeys);
  }

  resumeDefaultOn(key: string) {
    this.preventDefaultKeyCombos.delete(key);
  }

  private handleKeyDown(event: KeyboardEvent) {
    const updatedKeys = { ...this.pressedKeys.getValue(), [event.key]: true };
    this.pressedKeys.next(updatedKeys);

    if (this.preventDefaultOnKeyCombo()) {
      event.preventDefault();
    }
  }

  private handleKeyUp(event: KeyboardEvent) {
    const { [event.key]: removed, ...updatedKeys } = this.pressedKeys.getValue();
    this.pressedKeys.next(updatedKeys);
  }

  private hasShortcut(keys: { [key: string]: boolean }): boolean {
    const control = 'Control' in keys;
    const enter = 'Enter' in keys;
    const meta = 'Meta' in keys;
    const space = ' ' in keys;
    return (control && enter) || (meta && enter) || (control && space);
  }

  private isOnlyKey(key: string): boolean {
    return Object.keys(this.pressedKeys.getValue()).length === 1 && this.pressedKeys.getValue()[key];
  }

  /**
   * Checks if the current key combination is in the preventDefaultKeyCombos set.
   * If it is, it prevents the default behavior of the key combination.
   */
  private preventDefaultOnKeyCombo() {
    // Alphabetize the keys and join them with commas since the preventDefaultKeyCombos set is a set of sorted strings.
    const currentKeyCombo = Object.keys(this.pressedKeys.getValue())
      .map(key => key.trim())
      .sort()
      .join(',');
    return this.preventDefaultKeyCombos.has(currentKeyCombo);
  }

  private subscribeToKeyClick() {
    this.keyDown$.subscribe(this.handleKeyDown);
    this.keyUp$.subscribe(this.handleKeyUp);
  }
}
