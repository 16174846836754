export interface UserSettings {
  preferred_language: string;
  theme: UiTheme;
  ui_version: number;
}

export enum UiTheme {
  light = 'light',
  dark = 'dark',
  system = 'system'
}
