import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DateProxyPipe } from '../pipes/date-proxy.pipe';
import { PhonePipe } from '../pipes/phone-format.pipe';
import { FooterComponent } from './footer/footer.component';
import { SpinnerComponent } from './spinner/spinner.component';

@NgModule({
  declarations: [SpinnerComponent, FooterComponent, DateProxyPipe, PhonePipe],
  exports: [SpinnerComponent, FooterComponent, DateProxyPipe, PhonePipe],
  imports: [CommonModule, TranslateModule.forChild({ extend: true }), SweetAlert2Module]
})
export class SharedModule {}
