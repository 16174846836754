import { Component, Input, OnInit } from '@angular/core';

import { Event } from '../models/event';
import { EventMenuService } from '../services/event-menu.service';
import { TextQuestion } from '../models/conversation';

@Component({
  selector: 'app-event-action-menu',
  templateUrl: './event-action-menu.component.html',
  styleUrls: ['./event-action-menu.component.scss']
})
export class EventActionMenuComponent implements OnInit {
  @Input() event: Event;
  @Input() posX: number;
  @Input() posY: number;
  @Input() questions: TextQuestion[];

  constructor(public eventMenuService: EventMenuService) {}

  ngOnInit(): void {}

  clickOutside(event) {
    event.stopPropagation();
    this.eventMenuService.closeEventMenu();
  }

  onSelect(question: TextQuestion) {
    this.eventMenuService.onEventMenuSelection(question, this.event.text);
  }

  onEndConv() {
    this.eventMenuService.onEndConversation();
  }

  onOptOutConv() {
    this.eventMenuService.onOptOutConversation();
  }
}
