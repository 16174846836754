import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounce } from 'lodash';
import { Subject, combineLatest, merge } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, tap } from 'rxjs/operators';
import { MediaPreviewComponent } from '../media-preview/media-preview.component';
import { AiSuggestion, AiSuggestionText } from '../models/ai';
import { EventType, MMSEvent, TextEvent } from '../models/event';
import { TemplateMedia } from '../models/job';
import { Lead } from '../models/leads';
import { AlertsService } from '../services/alerts.service';
import { AuthService } from '../services/auth.service';
import { ConvsService } from '../services/convs.service';
import { DomainService } from '../services/domain.service';
import { JobsService } from '../services/jobs.service';
import { KeyClickService } from '../services/keyclick.service';
import { ResponderService } from '../services/responder.service';
import { TestJobsService } from '../services/test-jobs.service';
import { isMobile } from '../utils/screens';
import { RedactedService } from './../services/redacted.service';

@Component({
  selector: 'app-conv-actions-footer-default',
  templateUrl: './conv-actions-footer-default.component.html',
  styleUrls: ['./conv-actions-footer-default.component.scss']
})
export class ConvActionsFooterDefaultComponent implements AfterViewInit, OnChanges, OnInit {
  @ViewChild('inputRow', { static: true }) inputRow: ElementRef;
  @ViewChild('textarea', { static: false }) textarea: ElementRef;

  @Output() gotoEvent = new EventEmitter<string>();
  @Output() aiSuggestedTemplate = new EventEmitter<string>(); // emits template id
  @Output() aiSuggestedAnswer = new EventEmitter<{
    question_id: string;
    answer: string;
  }>();
  @Output() textEvent = new EventEmitter<TextEvent | MMSEvent>();

  @Input() aiSuggestion: AiSuggestion;
  @Input() lead: Lead;
  @Input() sendButtonStatus: string;

  aiSuggestionText: AiSuggestionText = null;
  canvasserPlaceholderText = `Message Type: Text Message
Sender: Campaign
Subject: Vote Reminder Message
Method: Canvasser`;
  emojiPickerIsOpen = false;
  isCollapsed = true;
  sendAndNewClickAndHold = false;
  sendAndNewMouseDownTimer: NodeJS.Timeout;
  templateMedia: TemplateMedia;
  templateText: string;
  text: FormControl;
  textareaIsFocused = false;

  canEditMessages$ = combineLatest([
    this.domainService.systemConfigs$.pipe(
      map(config => config.can_edit_messages),
      distinctUntilChanged()
    ),
    this.responderService.currentAgentIsResponder
  ]).pipe(
    map(([canEditMessages, currentAgentIsResponder]) => {
      if (canEditMessages === 'ALL') {
        return true;
      } else if (canEditMessages === 'RESPONDERS' && currentAgentIsResponder) {
        return true;
      }

      return false;
    })
  );

  clearTemplate$ = this.convsService.clearTemplate$.pipe(
    tap(template => {
      if (template === this.text.value) {
        this.injectTemplate('');
      }
    })
  );

  focusOnRouting$ = this.router.events.pipe(
    filter(e => e instanceof NavigationEnd),
    tap(() => {
      if (!isMobile() && this.textarea) {
        this.textarea.nativeElement.focus();
      }
    }),
    // This is a hack to get the focusOnRouting$ observable to emit on init so the observbale can be used in combineLatest below.
    startWith('')
  );

  // sendButtonClicked is a subject that emits a string when either of the send buttons are clicked.
  sendButtonClicked = new Subject<'send' | 'sendAndNew'>();

  // sendButtonClicked$ just turns the subject into an observable so we can use it in other streams.
  sendButtonClicked$ = this.sendButtonClicked.asObservable();

  /**
   * An observable that combines the shortcut key press events and send button click events.
   *
   * This observable merges two streams:
   * - `keyClickService.shortcutPressed$`: Emits 'sendAndNew' when a shortcut key combination is pressed.
   * - `sendButtonClicked$`: Emits 'send' or 'sendAndNew' when the send button is clicked.
   *
   * The merged stream then triggers the onSendMessage method with the appropriate argument based on the action.
   *
   * The `filter(() => this.convsService.getCanSendMessages())` ensures that the action is only triggered if the conversation
   * is in a state where sending messages is allowed.
   *
   * The `startWith('')` ensures that the observable emits an initial value, which is necessary for the observable to be hot.
   */
  sendAction$ = merge(
    this.keyClickService.shortcutPressed$.pipe(map(() => 'sendAndNew')),
    this.keyClickService.enterOnlyPressed$.pipe(
      map(() => 'send'),
      filter(() => this.textareaIsFocused)
    ),
    this.sendButtonClicked$
  ).pipe(
    filter(() => this.convsService.getCanSendMessages()),
    tap(action => {
      if (action === 'sendAndNew') {
        this.onSendMessage(true);
      } else if (action === 'send') {
        this.onSendMessage(false);
      }
    }),
    startWith('')
  );

  /**
   * Observable that combines the latest values from canEditMessages$, canSendMessages$,
   * clearTemplate$, sendAction$, and sendButtonReady$.
   *
   * It maps the combined values into an object containing:
   * - canEditMessages: Whether the current user can edit messages
   * - canSendMessages: Whether messages can be sent in the current context
   * - sendButtonReady: Whether the send button should be enabled
   *
   * This observable is used to provide the latest state for the message input
   * and send button functionality in the template.
   *
   * The clearTemplate$, sendAction$, and focusOnRouting$ are not used but are here to subscribe to them so they are hot observables
   * since the component depends on them to be active.
   */
  data$ = combineLatest([
    this.canEditMessages$,
    this.convsService.canSendMessages$,
    this.clearTemplate$,
    this.sendAction$,
    this.focusOnRouting$
  ]).pipe(
    map(([canEditMessages, canSendMessages, clearTemplate, sendAction, focusOnRouting]) => {
      return {
        canEditMessages,
        canSendMessages
      };
    })
  );

  /**
   * Wrapping the resizeTextarea function in a debounce to ensure the textarea to prevent
   * excessive resizing operations. It will call the resizeTextarea function immediately the
   * first time, and then revent successive calls to the function for 100ms.
   */
  private forceTextareaResize = () => {
    if (this.textarea) {
      debounce(
        () => {
          this.resizeTextarea();
        },
        100,
        { leading: true, trailing: false }
      )();
    }
    // tslint:disable-next-line: semicolon
  };

  constructor(
    public authService: AuthService,
    public jobsService: JobsService,
    public testJobsService: TestJobsService,
    private alertService: AlertsService,
    private convsService: ConvsService,
    private cd: ChangeDetectorRef,
    private domainService: DomainService,
    private responderService: ResponderService,
    private keyClickService: KeyClickService,
    private modalService: NgbModal,
    private router: Router,
    public redactedService: RedactedService
  ) {}

  get sendButtonText() {
    if (this.text.value) {
      if (this.sendButtonStatus === 'new') {
        return 'sendAndNew';
      }
      return 'sendAndNext';
    } else {
      if (this.sendButtonStatus === 'new') {
        return 'new';
      }
      return 'next';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.aiSuggestion) {
      this.setAiSuggestionText(changes.aiSuggestion.currentValue);
    }
  }

  ngOnInit() {
    this.buildForm();
    this.autoResizeTextarea();
  }

  ngAfterViewInit() {
    this.forceTextareaResize();
  }

  clearMedia() {
    this.templateMedia = null;
    this.forceTextareaResize();
  }

  injectTemplateText(text: string) {
    this.templateText = text;
    this.text.reset(text);

    if (this.redactedService.shouldRedact) {
      console.log('Injecting redacted text');
      const el = document.getElementById('redacted-textarea') as HTMLTextAreaElement;
      if (el) {
        el.value = this.canvasserPlaceholderText;
      }
    }

    if (!isMobile() && this.textarea) {
      this.textarea.nativeElement.focus();
    }
  }

  injectTemplate(text: string, media: TemplateMedia = null) {
    this.injectTemplateText(text);
    this.templateMedia = media;
    this.forceTextareaResize();
  }

  onGoTo(comp: string) {
    this.gotoEvent.emit(comp);
  }

  onSendMessage(thenStartNew: boolean = false) {
    if (this.text.value && this.text.valid) {
      const txt = this.stripAndValidateMessage(this.text);
      if (!txt) {
        return;
      }

      if (this.templateMedia) {
        console.log('Template has media', this.templateMedia);
        if (this.templateMedia.media_id) {
          this.textEvent.emit({
            messageType: EventType.mmsEvent,
            text: txt,
            thenStartNew,
            media_id: this.templateMedia.media_id,
            preview_url: this.templateMedia.preview_url,
            thumbnail_url: this.templateMedia.thumbnail_url,
            media_type: this.templateMedia.media_type,
            title: this.templateMedia.title
          } as MMSEvent);
        } else {
          this.textEvent.emit({
            messageType: EventType.mmsEvent,
            text: txt,
            thenStartNew,
            media: this.templateMedia.url,
            media_type: 'IMAGE',
            title: this.templateMedia.title
          } as MMSEvent);
        }
      } else {
        this.textEvent.emit({
          messageType: EventType.textEvent,
          text: txt,
          thenStartNew
        } as TextEvent);
      }

      this.resetForm();
    } else if (!this.text.value && thenStartNew) {
      this.convsService.startNewConv(this.sendButtonStatus);
    }
  }

  onTextareaBlur() {
    this.keyClickService.resumeDefaultOn('Enter');
    this.textareaIsFocused = false;
  }

  onTextareaFocus() {
    this.keyClickService.preventDefaultOn('Enter');
    this.textareaIsFocused = true;
  }

  openEmojiPicker() {
    if (this.aiSuggestionText) {
      return;
    }

    this.emojiPickerIsOpen = true;
  }

  onEmojiSelected(emoji: EmojiData) {
    const input = this.textarea.nativeElement;
    input.focus();

    if (document.execCommand) {
      const event = new Event('input');
      document.execCommand('insertText', false, emoji.native);
    } else {
      const [start, end] = [input.selectionStart, input.selectionEnd];
      input.setRangeText(emoji.native, start, end, 'end');
    }
    this.emojiPickerIsOpen = false;
  }

  previewMedia() {
    if (!this.templateMedia) {
      return;
    }

    const modalRef = this.modalService.open(MediaPreviewComponent, {
      centered: true,
      windowClass: 'media-preview-modal'
    });
    this.setModalPreviewValues(modalRef.componentInstance);
  }

  resetForm(text: string = null) {
    this.text.reset(text);
    this.forceTextareaResize();
    this.clearMedia();
  }

  onDismissAiSuggestion() {
    this.aiSuggestionText = null;
  }

  onAcceptAiSuggestion() {
    this.aiSuggestionText = null;

    if (this.aiSuggestion.type === 'question') {
      this.aiSuggestedAnswer.emit({
        question_id: this.aiSuggestion.question_id,
        answer: this.aiSuggestion.answer
      });
    } else if (this.aiSuggestion.type === 'template') {
      this.aiSuggestedTemplate.emit(this.aiSuggestion.template_id);
    }
  }

  private autoResizeTextarea() {
    if (this.textarea) {
      this.textarea.nativeElement.addEventListener(
        'input',
        () => {
          this.forceTextareaResize();
        },
        false
      );
    }
  }

  private buildForm() {
    this.text = new FormControl(this.templateText, [Validators.required]);
  }

  private resizeTextarea() {
    this.textarea.nativeElement.style.height = `auto`;
    this.textarea.nativeElement.style.height = `${this.textarea.nativeElement.scrollHeight}px`;
    this.cd.detectChanges();
  }

  private setAiSuggestionText(suggestion: AiSuggestion | null | undefined) {
    if (!suggestion) {
      this.aiSuggestionText = null;
      return;
    }

    if (suggestion.type === 'question') {
      const questionText = this.convsService.getCurrentConversationQuestionTextByQuestionId(suggestion.question_id);

      this.aiSuggestionText = {
        header: 'aiSuggestions.questionHeader',
        body: 'aiSuggestions.questionBody',
        bodyparams: {
          questionText,
          answer: suggestion.answer
        },
        footer: 'aiSuggestions.questionFooter',
        acceptButtonText: 'aiSuggestions.questionAcceptButton'
      };
    } else if (suggestion.type === 'template') {
      const template = this.jobsService.getTemplateById(suggestion.template_id);

      this.aiSuggestionText = {
        header: 'aiSuggestions.templateHeader',
        body: 'aiSuggestions.templateBody',
        bodyparams: {
          templateText: template.text
        },
        footer: 'aiSuggestions.templateFooter',
        acceptButtonText: 'aiSuggestions.templateAcceptButton'
      };
    }
  }

  private setModalPreviewValues(comp) {
    comp.type = this.templateMedia.media_id ? this.templateMedia.media_type : 'IMAGE';
    comp.url = this.templateMedia.media_id ? this.templateMedia.preview_url : this.templateMedia.url;
    comp.title = this.templateMedia.title;
  }

  private stripAndValidateMessage(text: FormControl): string | null {
    const txt = text.value.trim();
    if (!txt) {
      this.alertService.setMessage('alert.blankMessage');
      return null;
    }
    return txt;
  }
}
