import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { registerLocaleData } from '@angular/common';
import localeEsMX from '@angular/common/locales/es-MX';
import localeEsMXExtra from '@angular/common/locales/extra/es-MX';
import localeFrCAExtra from '@angular/common/locales/extra/fr-CA';
import localeFrCA from '@angular/common/locales/fr-CA';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { environment } from '../environments/environment';
import { AccountComponent } from './account/account.component';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AppComponent } from './app.component';
import { ErrorComponent } from './error/error.component';
import { AccountGuard } from './guards/account.guard';
import { AuthGuard } from './guards/auth.guard';
import { CanDeactivateSessionLogout } from './guards/session-logout.guard';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { ReloadAppComponent } from './reload-app/reload-app.component';
import { RequestPasswordResetComponent } from './request-password-reset/request-password-reset.component';
import { AuthService } from './services/auth.service';
import { ConvsService } from './services/convs.service';
import { DomainService } from './services/domain.service';
import { JobsService } from './services/jobs.service';
import { KeyClickService } from './services/keyclick.service';
import { LanguageService } from './services/language.service';
import { LeadsService } from './services/leads.service';
import { NotificationsService } from './services/notifications.service';
import { ResponderService } from './services/responder.service';
import { SidebarService } from './services/sidebar.service';
import { SignoutService } from './services/signout.service';
import { StatsService } from './services/stats.service';
import { TimeService } from './services/time.service';
import { TransferService } from './services/transfer.service';
import { SessionLogoutComponent } from './session-logout/session-logout.component';
import { SharedModule } from './shared/shared.module';
import { SignupComponent } from './signup/signup.component';
import { WINDOW_PROVIDERS } from './window.provider';
import { JobChangeAlertModalComponent } from './job-change-alert-modal/job-change-alert-modal.component';

registerLocaleData(localeFrCA, localeFrCAExtra);
registerLocaleData(localeEsMX, localeEsMXExtra);

// This allows the translation service to load the translate JSON files via http from the webserver.
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'accounts' },
  { path: 'a', redirectTo: 'accounts' },
  {
    path: 'accounts',
    children: [
      { path: '', pathMatch: 'full', component: AccountComponent },
      {
        path: ':accountId',
        children: [
          {
            path: 'adminlogin',
            component: AdminLoginComponent,
            pathMatch: 'full',
            canActivate: [AccountGuard]
          },
          {
            path: 'login',
            component: LoginComponent,
            pathMatch: 'full',
            canActivate: [AccountGuard]
          },
          {
            path: 'logout',
            component: LogoutComponent,
            pathMatch: 'full',
            canActivate: [AccountGuard]
          },
          {
            path: 'signup',
            component: SignupComponent,
            pathMatch: 'full',
            canActivate: [AccountGuard]
          },
          {
            path: 'requestreset',
            component: RequestPasswordResetComponent,
            pathMatch: 'full',
            canActivate: [AccountGuard]
          },
          {
            path: 'passwordreset',
            component: PasswordResetComponent,
            pathMatch: 'full',
            canActivate: [AccountGuard]
          },
          {
            path: 'register',
            component: PasswordResetComponent,
            pathMatch: 'full',
            canActivate: [AccountGuard]
          },
          {
            path: 'session-logout',
            component: SessionLogoutComponent,
            pathMatch: 'full',
            canActivate: [AccountGuard],
            canDeactivate: [CanDeactivateSessionLogout]
          },
          {
            path: '',
            loadChildren: () => import('./main/main.module').then(mod => mod.MainModule),
            canActivate: [AuthGuard]
          }
        ]
      }
    ]
  },
  { path: 'login', pathMatch: 'full', redirectTo: 'accounts' },
  { path: 'signup', pathMatch: 'full', redirectTo: 'accounts' },
  { path: 'pending', pathMatch: 'full', redirectTo: 'accounts' },
  { path: 'error', component: ErrorComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ErrorComponent,
    PageNotFoundComponent,
    SignupComponent,
    AccountComponent,
    ReloadAppComponent,
    LogoutComponent,
    AdminLoginComponent,
    PasswordResetComponent,
    RequestPasswordResetComponent,
    SessionLogoutComponent,
    JobChangeAlertModalComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, { enableTracing: false }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    AngularFireModule.initializeApp(environment.afConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    BrowserAnimationsModule,
    NgbModule,
    ReactiveFormsModule,
    SharedModule,
    SweetAlert2Module.forRoot({
      provideSwal: () => import('sweetalert2/dist/sweetalert2.js')
    })
  ],
  providers: [
    AuthGuard,
    AccountGuard,
    CanDeactivateSessionLogout,
    DomainService,
    AngularFireAuthGuard,
    AuthGuard,
    AuthService,
    ConvsService,
    DomainService,
    JobsService,
    KeyClickService,
    LanguageService,
    LeadsService,
    NotificationsService,
    ResponderService,
    SignoutService,
    SidebarService,
    StatsService,
    TimeService,
    TransferService,
    WINDOW_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
