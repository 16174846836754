import 'firebase/functions';

import * as firebase from 'firebase/app';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  private ready = false;
  private timeDiff: number;

  private getServerTime = firebase.functions().httpsCallable('time-serverTime');

  constructor() {
    this.updateTimeDiff = this.updateTimeDiff.bind(this);
    this.updateTimeDiff();
    setInterval(this.updateTimeDiff, 1000 * 60 * 5);
  }

  get now(): Date {
    const now = new Date();
    if (!this.ready) {
      return now;
    }
    now.setTime(now.getTime() + this.timeDiff);
    return now;
  }

  private updateTimeDiff() {
    const startTime = new Date().getTime();

    this.getServerTime({})
      .then(res => res.data)
      .then((serverTime: number) => {
        const endTime = new Date().getTime();
        const reqTime = endTime - startTime;
        this.timeDiff = serverTime + reqTime - endTime;
      })
      .then(() => {
        if (!this.ready) {
          this.ready = true;
        }
      });
  }
}
