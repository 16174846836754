import { Component, OnInit, Output, OnDestroy, EventEmitter } from '@angular/core';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';

@Component({
  selector: 'app-emoji-picker',
  template: `
    <div class="emojiPickerBackground" (click)="onClose()"></div>
    <emoji-mart
      color="rgb(21, 141, 188)"
      [darkMode]="false"
      [showPreview]="false"
      [style]="pickerStyles"
      (emojiSelect)="onSelect($event)"
    ></emoji-mart>
  `,
  styleUrls: ['./emoji-picker.component.scss']
})
export class EmojiPickerComponent implements OnDestroy, OnInit {
  @Output() close = new EventEmitter();
  @Output() selected = new EventEmitter<EmojiData>();

  pickerStyles = {
    position: 'absolute',

    bottom: '115px',
    left: '4px'
  };

  constructor() {}

  ngOnDestroy(): void {
    document.removeEventListener('keydown', this.handleKeydown);
  }

  ngOnInit(): void {
    this.handleKeydown = this.handleKeydown.bind(this);
    document.addEventListener('keydown', this.handleKeydown);
  }

  onClose(): void {
    this.close.emit(true);
  }

  onSelect(selection: { $event: Event; emoji: EmojiData }): void {
    this.selected.emit(selection.emoji);
  }

  private handleKeydown(e): void {
    if (e.key === 'Escape') {
      this.onClose();
    }
  }
}
