import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { StatsService } from '../services/stats.service';

const MIN_ACCEPTABLE_RATE = 7.7;

@Component({
  selector: 'app-transmission-rate-indicator',
  templateUrl: './transmission-rate-indicator.component.html',
  styleUrls: ['./transmission-rate-indicator.component.scss']
})
export class TransmissionRateIndicatorComponent implements OnInit {
  indicatorStatus$ = this.statsService.currentStatus$.pipe(
    map(status => {
      if (status.transmission_rates.five_min < MIN_ACCEPTABLE_RATE) {
        return 'bad';
      } else if (status.transmission_rates.five_min >= MIN_ACCEPTABLE_RATE) {
        return 'good';
      }
      return null;
    })
  );

  constructor(private statsService: StatsService) {}

  ngOnInit(): void {}
}
